<script>
    import { _ } from "svelte-i18n";
    import { Router, Route } from "svelte-routing";
    import { Container } from "sveltestrap";
    import api from "./api/api";
    import { authInitialized, loggedIn } from "./stores";
    import Login from "./routes/Login.svelte";
    import MainNavbar from "./components/MainNavbar.svelte";
    import Dashboard from "./routes/Dashboard.svelte";
    import SpotifyCallback from "./routes/SpotifyCallback.svelte";

    if (!$authInitialized) {
        $authInitialized = true;
        $loggedIn = api.loadAuth();
    }
</script>

<Router basepath="/">
    <div class="mb-3">
        <MainNavbar />
    </div>

    <Container>
        <Route component={Dashboard} path="/" />
        <Route component={Login} path="/login" />
        <Route component={SpotifyCallback} path="/spotify-callback" />
    </Container>
</Router>

<style global src="../scss/main.scss">/*
$enable-gradients: true;
$enable-shadows: true;
$body-color: purple;
$body-bg: #f5f5f5;
*/
:global(:root) {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f07167;
  --orange: #ff8552;
  --yellow: #f5d547;
  --green: #1db954;
  --teal: #20c997;
  --cyan: #4059ad;
  --white: #fff;
  --gray: #4a5759;
  --gray-dark: #343a40;
  --primary: #1db954;
  --secondary: #4a5759;
  --success: #1db954;
  --info: #4059ad;
  --warning: #f5d547;
  --danger: #f07167;
  --light: #dedbd2;
  --dark: #020122;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

:global(*),
:global(*::before),
:global(*::after) {
  box-sizing: border-box; }

:global(html) {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

:global(article), :global(aside), :global(figcaption), :global(figure), :global(footer), :global(header), :global(hgroup), :global(main), :global(nav), :global(section) {
  display: block; }

:global(body) {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

:global([tabindex="-1"]:focus:not(:focus-visible)) {
  outline: 0 !important; }

:global(hr) {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

:global(h1), :global(h2), :global(h3), :global(h4), :global(h5), :global(h6) {
  margin-top: 0;
  margin-bottom: 0.5rem; }

:global(p) {
  margin-top: 0;
  margin-bottom: 1rem; }

:global(abbr[title]),
:global(abbr[data-original-title]) {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

:global(address) {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

:global(ol),
:global(ul),
:global(dl) {
  margin-top: 0;
  margin-bottom: 1rem; }

:global(ol) :global(ol),
:global(ul) :global(ul),
:global(ol) :global(ul),
:global(ul) :global(ol) {
  margin-bottom: 0; }

:global(dt) {
  font-weight: 700; }

:global(dd) {
  margin-bottom: .5rem;
  margin-left: 0; }

:global(blockquote) {
  margin: 0 0 1rem; }

:global(b),
:global(strong) {
  font-weight: bolder; }

:global(small) {
  font-size: 80%; }

:global(sub),
:global(sup) {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

:global(sub) {
  bottom: -.25em; }

:global(sup) {
  top: -.5em; }

:global(a) {
  color: #1db954;
  text-decoration: none;
  background-color: transparent; }
  :global(a:hover) {
    color: #137736;
    text-decoration: underline; }

:global(a:not([href]):not([class])) {
  color: inherit;
  text-decoration: none; }
  :global(a:not([href]):not([class]):hover) {
    color: inherit;
    text-decoration: none; }

:global(pre),
:global(code),
:global(kbd),
:global(samp) {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

:global(pre) {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

:global(figure) {
  margin: 0 0 1rem; }

:global(img) {
  vertical-align: middle;
  border-style: none; }

:global(svg) {
  overflow: hidden;
  vertical-align: middle; }

:global(table) {
  border-collapse: collapse; }

:global(caption) {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

:global(th) {
  text-align: inherit;
  text-align: -webkit-match-parent; }

:global(label) {
  display: inline-block;
  margin-bottom: 0.5rem; }

:global(button) {
  border-radius: 0; }

:global(button:focus:not(:focus-visible)) {
  outline: 0; }

:global(input),
:global(button),
:global(select),
:global(optgroup),
:global(textarea) {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

:global(button),
:global(input) {
  overflow: visible; }

:global(button),
:global(select) {
  text-transform: none; }

:global([role="button"]) {
  cursor: pointer; }

:global(select) {
  word-wrap: normal; }

:global(button),
:global([type="button"]),
:global([type="reset"]),
:global([type="submit"]) {
  -webkit-appearance: button; }

:global(button:not(:disabled)),
:global([type="button"]:not(:disabled)),
:global([type="reset"]:not(:disabled)),
:global([type="submit"]:not(:disabled)) {
  cursor: pointer; }

:global(button::-moz-focus-inner),
:global([type="button"]::-moz-focus-inner),
:global([type="reset"]::-moz-focus-inner),
:global([type="submit"]::-moz-focus-inner) {
  padding: 0;
  border-style: none; }

:global(input[type="radio"]),
:global(input[type="checkbox"]) {
  box-sizing: border-box;
  padding: 0; }

:global(textarea) {
  overflow: auto;
  resize: vertical; }

:global(fieldset) {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

:global(legend) {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

:global(progress) {
  vertical-align: baseline; }

:global([type="number"]::-webkit-inner-spin-button),
:global([type="number"]::-webkit-outer-spin-button) {
  height: auto; }

:global([type="search"]) {
  outline-offset: -2px;
  -webkit-appearance: none; }

:global([type="search"]::-webkit-search-decoration) {
  -webkit-appearance: none; }

:global(::-webkit-file-upload-button) {
  font: inherit;
  -webkit-appearance: button; }

:global(output) {
  display: inline-block; }

:global(summary) {
  display: list-item;
  cursor: pointer; }

:global(template) {
  display: none; }

:global([hidden]) {
  display: none !important; }

:global(h1), :global(h2), :global(h3), :global(h4), :global(h5), :global(h6), :global(.h1), :global(.h2), :global(.h3), :global(.h4), :global(.h5), :global(.h6) {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

:global(h1), :global(.h1) {
  font-size: 2.5rem; }

:global(h2), :global(.h2) {
  font-size: 2rem; }

:global(h3), :global(.h3) {
  font-size: 1.75rem; }

:global(h4), :global(.h4) {
  font-size: 1.5rem; }

:global(h5), :global(.h5) {
  font-size: 1.25rem; }

:global(h6), :global(.h6) {
  font-size: 1rem; }

:global(.lead) {
  font-size: 1.25rem;
  font-weight: 300; }

:global(.display-1) {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

:global(.display-2) {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

:global(.display-3) {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

:global(.display-4) {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

:global(hr) {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

:global(small),
:global(.small) {
  font-size: 80%;
  font-weight: 400; }

:global(mark),
:global(.mark) {
  padding: 0.2em;
  background-color: #fcf8e3; }

:global(.list-unstyled) {
  padding-left: 0;
  list-style: none; }

:global(.list-inline) {
  padding-left: 0;
  list-style: none; }

:global(.list-inline-item) {
  display: inline-block; }
  :global(.list-inline-item:not(:last-child)) {
    margin-right: 0.5rem; }

:global(.initialism) {
  font-size: 90%;
  text-transform: uppercase; }

:global(.blockquote) {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

:global(.blockquote-footer) {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  :global(.blockquote-footer::before) {
    content: "\2014\00A0"; }

:global(code) {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  :global(a) > :global(code) {
    color: inherit; }

:global(kbd) {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 15px; }
  :global(kbd) :global(kbd) {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

:global(pre) {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  :global(pre) :global(code) {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

:global(.pre-scrollable) {
  max-height: 340px;
  overflow-y: scroll; }

:global(.container),
:global(.container-fluid),
:global(.container-sm),
:global(.container-md),
:global(.container-lg),
:global(.container-xl) {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  :global(.container), :global(.container-sm) {
    max-width: 540px; } }

@media (min-width: 768px) {
  :global(.container), :global(.container-sm), :global(.container-md) {
    max-width: 720px; } }

@media (min-width: 992px) {
  :global(.container), :global(.container-sm), :global(.container-md), :global(.container-lg) {
    max-width: 960px; } }

@media (min-width: 1200px) {
  :global(.container), :global(.container-sm), :global(.container-md), :global(.container-lg), :global(.container-xl) {
    max-width: 1140px; } }

:global(.row) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

:global(.no-gutters) {
  margin-right: 0;
  margin-left: 0; }
  :global(.no-gutters) > :global(.col),
  :global(.no-gutters) > :global([class*="col-"]) {
    padding-right: 0;
    padding-left: 0; }

:global(.col-1), :global(.col-2), :global(.col-3), :global(.col-4), :global(.col-5), :global(.col-6), :global(.col-7), :global(.col-8), :global(.col-9), :global(.col-10), :global(.col-11), :global(.col-12), :global(.col), :global(.col-auto), :global(.col-sm-1), :global(.col-sm-2), :global(.col-sm-3), :global(.col-sm-4), :global(.col-sm-5), :global(.col-sm-6), :global(.col-sm-7), :global(.col-sm-8), :global(.col-sm-9), :global(.col-sm-10), :global(.col-sm-11), :global(.col-sm-12), :global(.col-sm), :global(.col-sm-auto), :global(.col-md-1), :global(.col-md-2), :global(.col-md-3), :global(.col-md-4), :global(.col-md-5), :global(.col-md-6), :global(.col-md-7), :global(.col-md-8), :global(.col-md-9), :global(.col-md-10), :global(.col-md-11), :global(.col-md-12), :global(.col-md), :global(.col-md-auto), :global(.col-lg-1), :global(.col-lg-2), :global(.col-lg-3), :global(.col-lg-4), :global(.col-lg-5), :global(.col-lg-6), :global(.col-lg-7), :global(.col-lg-8), :global(.col-lg-9), :global(.col-lg-10), :global(.col-lg-11), :global(.col-lg-12), :global(.col-lg), :global(.col-lg-auto), :global(.col-xl-1), :global(.col-xl-2), :global(.col-xl-3), :global(.col-xl-4), :global(.col-xl-5), :global(.col-xl-6), :global(.col-xl-7), :global(.col-xl-8), :global(.col-xl-9), :global(.col-xl-10), :global(.col-xl-11), :global(.col-xl-12), :global(.col-xl), :global(.col-xl-auto) {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

:global(.col) {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

:global(.row-cols-1) > :global(*) {
  flex: 0 0 100%;
  max-width: 100%; }

:global(.row-cols-2) > :global(*) {
  flex: 0 0 50%;
  max-width: 50%; }

:global(.row-cols-3) > :global(*) {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

:global(.row-cols-4) > :global(*) {
  flex: 0 0 25%;
  max-width: 25%; }

:global(.row-cols-5) > :global(*) {
  flex: 0 0 20%;
  max-width: 20%; }

:global(.row-cols-6) > :global(*) {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

:global(.col-auto) {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

:global(.col-1) {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

:global(.col-2) {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

:global(.col-3) {
  flex: 0 0 25%;
  max-width: 25%; }

:global(.col-4) {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

:global(.col-5) {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

:global(.col-6) {
  flex: 0 0 50%;
  max-width: 50%; }

:global(.col-7) {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

:global(.col-8) {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

:global(.col-9) {
  flex: 0 0 75%;
  max-width: 75%; }

:global(.col-10) {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

:global(.col-11) {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

:global(.col-12) {
  flex: 0 0 100%;
  max-width: 100%; }

:global(.order-first) {
  order: -1; }

:global(.order-last) {
  order: 13; }

:global(.order-0) {
  order: 0; }

:global(.order-1) {
  order: 1; }

:global(.order-2) {
  order: 2; }

:global(.order-3) {
  order: 3; }

:global(.order-4) {
  order: 4; }

:global(.order-5) {
  order: 5; }

:global(.order-6) {
  order: 6; }

:global(.order-7) {
  order: 7; }

:global(.order-8) {
  order: 8; }

:global(.order-9) {
  order: 9; }

:global(.order-10) {
  order: 10; }

:global(.order-11) {
  order: 11; }

:global(.order-12) {
  order: 12; }

:global(.offset-1) {
  margin-left: 8.33333%; }

:global(.offset-2) {
  margin-left: 16.66667%; }

:global(.offset-3) {
  margin-left: 25%; }

:global(.offset-4) {
  margin-left: 33.33333%; }

:global(.offset-5) {
  margin-left: 41.66667%; }

:global(.offset-6) {
  margin-left: 50%; }

:global(.offset-7) {
  margin-left: 58.33333%; }

:global(.offset-8) {
  margin-left: 66.66667%; }

:global(.offset-9) {
  margin-left: 75%; }

:global(.offset-10) {
  margin-left: 83.33333%; }

:global(.offset-11) {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  :global(.col-sm) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  :global(.row-cols-sm-1) > :global(*) {
    flex: 0 0 100%;
    max-width: 100%; }
  :global(.row-cols-sm-2) > :global(*) {
    flex: 0 0 50%;
    max-width: 50%; }
  :global(.row-cols-sm-3) > :global(*) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  :global(.row-cols-sm-4) > :global(*) {
    flex: 0 0 25%;
    max-width: 25%; }
  :global(.row-cols-sm-5) > :global(*) {
    flex: 0 0 20%;
    max-width: 20%; }
  :global(.row-cols-sm-6) > :global(*) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  :global(.col-sm-auto) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  :global(.col-sm-1) {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  :global(.col-sm-2) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  :global(.col-sm-3) {
    flex: 0 0 25%;
    max-width: 25%; }
  :global(.col-sm-4) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  :global(.col-sm-5) {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  :global(.col-sm-6) {
    flex: 0 0 50%;
    max-width: 50%; }
  :global(.col-sm-7) {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  :global(.col-sm-8) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  :global(.col-sm-9) {
    flex: 0 0 75%;
    max-width: 75%; }
  :global(.col-sm-10) {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  :global(.col-sm-11) {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  :global(.col-sm-12) {
    flex: 0 0 100%;
    max-width: 100%; }
  :global(.order-sm-first) {
    order: -1; }
  :global(.order-sm-last) {
    order: 13; }
  :global(.order-sm-0) {
    order: 0; }
  :global(.order-sm-1) {
    order: 1; }
  :global(.order-sm-2) {
    order: 2; }
  :global(.order-sm-3) {
    order: 3; }
  :global(.order-sm-4) {
    order: 4; }
  :global(.order-sm-5) {
    order: 5; }
  :global(.order-sm-6) {
    order: 6; }
  :global(.order-sm-7) {
    order: 7; }
  :global(.order-sm-8) {
    order: 8; }
  :global(.order-sm-9) {
    order: 9; }
  :global(.order-sm-10) {
    order: 10; }
  :global(.order-sm-11) {
    order: 11; }
  :global(.order-sm-12) {
    order: 12; }
  :global(.offset-sm-0) {
    margin-left: 0; }
  :global(.offset-sm-1) {
    margin-left: 8.33333%; }
  :global(.offset-sm-2) {
    margin-left: 16.66667%; }
  :global(.offset-sm-3) {
    margin-left: 25%; }
  :global(.offset-sm-4) {
    margin-left: 33.33333%; }
  :global(.offset-sm-5) {
    margin-left: 41.66667%; }
  :global(.offset-sm-6) {
    margin-left: 50%; }
  :global(.offset-sm-7) {
    margin-left: 58.33333%; }
  :global(.offset-sm-8) {
    margin-left: 66.66667%; }
  :global(.offset-sm-9) {
    margin-left: 75%; }
  :global(.offset-sm-10) {
    margin-left: 83.33333%; }
  :global(.offset-sm-11) {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  :global(.col-md) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  :global(.row-cols-md-1) > :global(*) {
    flex: 0 0 100%;
    max-width: 100%; }
  :global(.row-cols-md-2) > :global(*) {
    flex: 0 0 50%;
    max-width: 50%; }
  :global(.row-cols-md-3) > :global(*) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  :global(.row-cols-md-4) > :global(*) {
    flex: 0 0 25%;
    max-width: 25%; }
  :global(.row-cols-md-5) > :global(*) {
    flex: 0 0 20%;
    max-width: 20%; }
  :global(.row-cols-md-6) > :global(*) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  :global(.col-md-auto) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  :global(.col-md-1) {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  :global(.col-md-2) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  :global(.col-md-3) {
    flex: 0 0 25%;
    max-width: 25%; }
  :global(.col-md-4) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  :global(.col-md-5) {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  :global(.col-md-6) {
    flex: 0 0 50%;
    max-width: 50%; }
  :global(.col-md-7) {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  :global(.col-md-8) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  :global(.col-md-9) {
    flex: 0 0 75%;
    max-width: 75%; }
  :global(.col-md-10) {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  :global(.col-md-11) {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  :global(.col-md-12) {
    flex: 0 0 100%;
    max-width: 100%; }
  :global(.order-md-first) {
    order: -1; }
  :global(.order-md-last) {
    order: 13; }
  :global(.order-md-0) {
    order: 0; }
  :global(.order-md-1) {
    order: 1; }
  :global(.order-md-2) {
    order: 2; }
  :global(.order-md-3) {
    order: 3; }
  :global(.order-md-4) {
    order: 4; }
  :global(.order-md-5) {
    order: 5; }
  :global(.order-md-6) {
    order: 6; }
  :global(.order-md-7) {
    order: 7; }
  :global(.order-md-8) {
    order: 8; }
  :global(.order-md-9) {
    order: 9; }
  :global(.order-md-10) {
    order: 10; }
  :global(.order-md-11) {
    order: 11; }
  :global(.order-md-12) {
    order: 12; }
  :global(.offset-md-0) {
    margin-left: 0; }
  :global(.offset-md-1) {
    margin-left: 8.33333%; }
  :global(.offset-md-2) {
    margin-left: 16.66667%; }
  :global(.offset-md-3) {
    margin-left: 25%; }
  :global(.offset-md-4) {
    margin-left: 33.33333%; }
  :global(.offset-md-5) {
    margin-left: 41.66667%; }
  :global(.offset-md-6) {
    margin-left: 50%; }
  :global(.offset-md-7) {
    margin-left: 58.33333%; }
  :global(.offset-md-8) {
    margin-left: 66.66667%; }
  :global(.offset-md-9) {
    margin-left: 75%; }
  :global(.offset-md-10) {
    margin-left: 83.33333%; }
  :global(.offset-md-11) {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  :global(.col-lg) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  :global(.row-cols-lg-1) > :global(*) {
    flex: 0 0 100%;
    max-width: 100%; }
  :global(.row-cols-lg-2) > :global(*) {
    flex: 0 0 50%;
    max-width: 50%; }
  :global(.row-cols-lg-3) > :global(*) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  :global(.row-cols-lg-4) > :global(*) {
    flex: 0 0 25%;
    max-width: 25%; }
  :global(.row-cols-lg-5) > :global(*) {
    flex: 0 0 20%;
    max-width: 20%; }
  :global(.row-cols-lg-6) > :global(*) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  :global(.col-lg-auto) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  :global(.col-lg-1) {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  :global(.col-lg-2) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  :global(.col-lg-3) {
    flex: 0 0 25%;
    max-width: 25%; }
  :global(.col-lg-4) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  :global(.col-lg-5) {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  :global(.col-lg-6) {
    flex: 0 0 50%;
    max-width: 50%; }
  :global(.col-lg-7) {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  :global(.col-lg-8) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  :global(.col-lg-9) {
    flex: 0 0 75%;
    max-width: 75%; }
  :global(.col-lg-10) {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  :global(.col-lg-11) {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  :global(.col-lg-12) {
    flex: 0 0 100%;
    max-width: 100%; }
  :global(.order-lg-first) {
    order: -1; }
  :global(.order-lg-last) {
    order: 13; }
  :global(.order-lg-0) {
    order: 0; }
  :global(.order-lg-1) {
    order: 1; }
  :global(.order-lg-2) {
    order: 2; }
  :global(.order-lg-3) {
    order: 3; }
  :global(.order-lg-4) {
    order: 4; }
  :global(.order-lg-5) {
    order: 5; }
  :global(.order-lg-6) {
    order: 6; }
  :global(.order-lg-7) {
    order: 7; }
  :global(.order-lg-8) {
    order: 8; }
  :global(.order-lg-9) {
    order: 9; }
  :global(.order-lg-10) {
    order: 10; }
  :global(.order-lg-11) {
    order: 11; }
  :global(.order-lg-12) {
    order: 12; }
  :global(.offset-lg-0) {
    margin-left: 0; }
  :global(.offset-lg-1) {
    margin-left: 8.33333%; }
  :global(.offset-lg-2) {
    margin-left: 16.66667%; }
  :global(.offset-lg-3) {
    margin-left: 25%; }
  :global(.offset-lg-4) {
    margin-left: 33.33333%; }
  :global(.offset-lg-5) {
    margin-left: 41.66667%; }
  :global(.offset-lg-6) {
    margin-left: 50%; }
  :global(.offset-lg-7) {
    margin-left: 58.33333%; }
  :global(.offset-lg-8) {
    margin-left: 66.66667%; }
  :global(.offset-lg-9) {
    margin-left: 75%; }
  :global(.offset-lg-10) {
    margin-left: 83.33333%; }
  :global(.offset-lg-11) {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  :global(.col-xl) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  :global(.row-cols-xl-1) > :global(*) {
    flex: 0 0 100%;
    max-width: 100%; }
  :global(.row-cols-xl-2) > :global(*) {
    flex: 0 0 50%;
    max-width: 50%; }
  :global(.row-cols-xl-3) > :global(*) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  :global(.row-cols-xl-4) > :global(*) {
    flex: 0 0 25%;
    max-width: 25%; }
  :global(.row-cols-xl-5) > :global(*) {
    flex: 0 0 20%;
    max-width: 20%; }
  :global(.row-cols-xl-6) > :global(*) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  :global(.col-xl-auto) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  :global(.col-xl-1) {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  :global(.col-xl-2) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  :global(.col-xl-3) {
    flex: 0 0 25%;
    max-width: 25%; }
  :global(.col-xl-4) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  :global(.col-xl-5) {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  :global(.col-xl-6) {
    flex: 0 0 50%;
    max-width: 50%; }
  :global(.col-xl-7) {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  :global(.col-xl-8) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  :global(.col-xl-9) {
    flex: 0 0 75%;
    max-width: 75%; }
  :global(.col-xl-10) {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  :global(.col-xl-11) {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  :global(.col-xl-12) {
    flex: 0 0 100%;
    max-width: 100%; }
  :global(.order-xl-first) {
    order: -1; }
  :global(.order-xl-last) {
    order: 13; }
  :global(.order-xl-0) {
    order: 0; }
  :global(.order-xl-1) {
    order: 1; }
  :global(.order-xl-2) {
    order: 2; }
  :global(.order-xl-3) {
    order: 3; }
  :global(.order-xl-4) {
    order: 4; }
  :global(.order-xl-5) {
    order: 5; }
  :global(.order-xl-6) {
    order: 6; }
  :global(.order-xl-7) {
    order: 7; }
  :global(.order-xl-8) {
    order: 8; }
  :global(.order-xl-9) {
    order: 9; }
  :global(.order-xl-10) {
    order: 10; }
  :global(.order-xl-11) {
    order: 11; }
  :global(.order-xl-12) {
    order: 12; }
  :global(.offset-xl-0) {
    margin-left: 0; }
  :global(.offset-xl-1) {
    margin-left: 8.33333%; }
  :global(.offset-xl-2) {
    margin-left: 16.66667%; }
  :global(.offset-xl-3) {
    margin-left: 25%; }
  :global(.offset-xl-4) {
    margin-left: 33.33333%; }
  :global(.offset-xl-5) {
    margin-left: 41.66667%; }
  :global(.offset-xl-6) {
    margin-left: 50%; }
  :global(.offset-xl-7) {
    margin-left: 58.33333%; }
  :global(.offset-xl-8) {
    margin-left: 66.66667%; }
  :global(.offset-xl-9) {
    margin-left: 75%; }
  :global(.offset-xl-10) {
    margin-left: 83.33333%; }
  :global(.offset-xl-11) {
    margin-left: 91.66667%; } }

:global(.form-control) {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 20px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    :global(.form-control) {
      transition: none; } }
  :global(.form-control::-ms-expand) {
    background-color: transparent;
    border: 0; }
  :global(.form-control:-moz-focusring) {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  :global(.form-control:focus) {
    color: #495057;
    background-color: #fff;
    border-color: #6de899;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }
  :global(.form-control::placeholder) {
    color: #6c757d;
    opacity: 1; }
  :global(.form-control:disabled), :global(.form-control[readonly]) {
    background-color: #e9ecef;
    opacity: 1; }

:global(input[type="date"].form-control),
:global(input[type="time"].form-control),
:global(input[type="datetime-local"].form-control),
:global(input[type="month"].form-control) {
  appearance: none; }

:global(select.form-control:focus::-ms-value) {
  color: #495057;
  background-color: #fff; }

:global(.form-control-file),
:global(.form-control-range) {
  display: block;
  width: 100%; }

:global(.col-form-label) {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

:global(.col-form-label-lg) {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

:global(.col-form-label-sm) {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

:global(.form-control-plaintext) {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  :global(.form-control-plaintext.form-control-sm), :global(.form-control-plaintext.form-control-lg) {
    padding-right: 0;
    padding-left: 0; }

:global(.form-control-sm) {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 15px; }

:global(.form-control-lg) {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 25px; }

:global(select.form-control[size]), :global(select.form-control[multiple]) {
  height: auto; }

:global(textarea.form-control) {
  height: auto; }

:global(.form-group) {
  margin-bottom: 1rem; }

:global(.form-text) {
  display: block;
  margin-top: 0.25rem; }

:global(.form-row) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  :global(.form-row) > :global(.col),
  :global(.form-row) > :global([class*="col-"]) {
    padding-right: 5px;
    padding-left: 5px; }

:global(.form-check) {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

:global(.form-check-input) {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  :global(.form-check-input[disabled]) ~ :global(.form-check-label),
  :global(.form-check-input:disabled) ~ :global(.form-check-label) {
    color: #6c757d; }

:global(.form-check-label) {
  margin-bottom: 0; }

:global(.form-check-inline) {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  :global(.form-check-inline) :global(.form-check-input) {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

:global(.valid-feedback) {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #1db954; }

:global(.valid-tooltip) {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(29, 185, 84, 0.9);
  border-radius: 20px; }
  :global(.form-row) > :global(.col) > :global(.valid-tooltip),
  :global(.form-row) > :global([class*="col-"]) > :global(.valid-tooltip) {
    left: 5px; }

:global(.was-validated) :global(:valid) ~ :global(.valid-feedback),
:global(.was-validated) :global(:valid) ~ :global(.valid-tooltip),
:global(.is-valid) ~ :global(.valid-feedback),
:global(.is-valid) ~ :global(.valid-tooltip) {
  display: block; }

:global(.was-validated) :global(.form-control:valid), :global(.form-control.is-valid) {
  border-color: #1db954;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231db954' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  :global(.was-validated) :global(.form-control:valid:focus), :global(.form-control.is-valid:focus) {
    border-color: #1db954;
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }

:global(.was-validated) :global(textarea.form-control:valid), :global(textarea.form-control.is-valid) {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

:global(.was-validated) :global(.custom-select:valid), :global(.custom-select.is-valid) {
  border-color: #1db954;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231db954' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  :global(.was-validated) :global(.custom-select:valid:focus), :global(.custom-select.is-valid:focus) {
    border-color: #1db954;
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }

:global(.was-validated) :global(.form-check-input:valid) ~ :global(.form-check-label), :global(.form-check-input.is-valid) ~ :global(.form-check-label) {
  color: #1db954; }

:global(.was-validated) :global(.form-check-input:valid) ~ :global(.valid-feedback),
:global(.was-validated) :global(.form-check-input:valid) ~ :global(.valid-tooltip),
:global(.form-check-input.is-valid) ~ :global(.valid-feedback),
:global(.form-check-input.is-valid) ~ :global(.valid-tooltip) {
  display: block; }

:global(.was-validated) :global(.custom-control-input:valid) ~ :global(.custom-control-label), :global(.custom-control-input.is-valid) ~ :global(.custom-control-label) {
  color: #1db954; }
  :global(.was-validated) :global(.custom-control-input:valid) ~ :global(.custom-control-label::before), :global(.custom-control-input.is-valid) ~ :global(.custom-control-label::before) {
    border-color: #1db954; }

:global(.was-validated) :global(.custom-control-input:valid:checked) ~ :global(.custom-control-label::before), :global(.custom-control-input.is-valid:checked) ~ :global(.custom-control-label::before) {
  border-color: #2bde6a;
  background-color: #2bde6a; }

:global(.was-validated) :global(.custom-control-input:valid:focus) ~ :global(.custom-control-label::before), :global(.custom-control-input.is-valid:focus) ~ :global(.custom-control-label::before) {
  box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }

:global(.was-validated) :global(.custom-control-input:valid:focus:not(:checked)) ~ :global(.custom-control-label::before), :global(.custom-control-input.is-valid:focus:not(:checked)) ~ :global(.custom-control-label::before) {
  border-color: #1db954; }

:global(.was-validated) :global(.custom-file-input:valid) ~ :global(.custom-file-label), :global(.custom-file-input.is-valid) ~ :global(.custom-file-label) {
  border-color: #1db954; }

:global(.was-validated) :global(.custom-file-input:valid:focus) ~ :global(.custom-file-label), :global(.custom-file-input.is-valid:focus) ~ :global(.custom-file-label) {
  border-color: #1db954;
  box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }

:global(.invalid-feedback) {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f07167; }

:global(.invalid-tooltip) {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(240, 113, 103, 0.9);
  border-radius: 20px; }
  :global(.form-row) > :global(.col) > :global(.invalid-tooltip),
  :global(.form-row) > :global([class*="col-"]) > :global(.invalid-tooltip) {
    left: 5px; }

:global(.was-validated) :global(:invalid) ~ :global(.invalid-feedback),
:global(.was-validated) :global(:invalid) ~ :global(.invalid-tooltip),
:global(.is-invalid) ~ :global(.invalid-feedback),
:global(.is-invalid) ~ :global(.invalid-tooltip) {
  display: block; }

:global(.was-validated) :global(.form-control:invalid), :global(.form-control.is-invalid) {
  border-color: #f07167;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f07167' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f07167' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  :global(.was-validated) :global(.form-control:invalid:focus), :global(.form-control.is-invalid:focus) {
    border-color: #f07167;
    box-shadow: 0 0 0 0.2rem rgba(240, 113, 103, 0.25); }

:global(.was-validated) :global(textarea.form-control:invalid), :global(textarea.form-control.is-invalid) {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

:global(.was-validated) :global(.custom-select:invalid), :global(.custom-select.is-invalid) {
  border-color: #f07167;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f07167' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f07167' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  :global(.was-validated) :global(.custom-select:invalid:focus), :global(.custom-select.is-invalid:focus) {
    border-color: #f07167;
    box-shadow: 0 0 0 0.2rem rgba(240, 113, 103, 0.25); }

:global(.was-validated) :global(.form-check-input:invalid) ~ :global(.form-check-label), :global(.form-check-input.is-invalid) ~ :global(.form-check-label) {
  color: #f07167; }

:global(.was-validated) :global(.form-check-input:invalid) ~ :global(.invalid-feedback),
:global(.was-validated) :global(.form-check-input:invalid) ~ :global(.invalid-tooltip),
:global(.form-check-input.is-invalid) ~ :global(.invalid-feedback),
:global(.form-check-input.is-invalid) ~ :global(.invalid-tooltip) {
  display: block; }

:global(.was-validated) :global(.custom-control-input:invalid) ~ :global(.custom-control-label), :global(.custom-control-input.is-invalid) ~ :global(.custom-control-label) {
  color: #f07167; }
  :global(.was-validated) :global(.custom-control-input:invalid) ~ :global(.custom-control-label::before), :global(.custom-control-input.is-invalid) ~ :global(.custom-control-label::before) {
    border-color: #f07167; }

:global(.was-validated) :global(.custom-control-input:invalid:checked) ~ :global(.custom-control-label::before), :global(.custom-control-input.is-invalid:checked) ~ :global(.custom-control-label::before) {
  border-color: #f59c95;
  background-color: #f59c95; }

:global(.was-validated) :global(.custom-control-input:invalid:focus) ~ :global(.custom-control-label::before), :global(.custom-control-input.is-invalid:focus) ~ :global(.custom-control-label::before) {
  box-shadow: 0 0 0 0.2rem rgba(240, 113, 103, 0.25); }

:global(.was-validated) :global(.custom-control-input:invalid:focus:not(:checked)) ~ :global(.custom-control-label::before), :global(.custom-control-input.is-invalid:focus:not(:checked)) ~ :global(.custom-control-label::before) {
  border-color: #f07167; }

:global(.was-validated) :global(.custom-file-input:invalid) ~ :global(.custom-file-label), :global(.custom-file-input.is-invalid) ~ :global(.custom-file-label) {
  border-color: #f07167; }

:global(.was-validated) :global(.custom-file-input:invalid:focus) ~ :global(.custom-file-label), :global(.custom-file-input.is-invalid:focus) ~ :global(.custom-file-label) {
  border-color: #f07167;
  box-shadow: 0 0 0 0.2rem rgba(240, 113, 103, 0.25); }

:global(.form-inline) {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  :global(.form-inline) :global(.form-check) {
    width: 100%; }
  @media (min-width: 576px) {
    :global(.form-inline) :global(label) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    :global(.form-inline) :global(.form-group) {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    :global(.form-inline) :global(.form-control) {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    :global(.form-inline) :global(.form-control-plaintext) {
      display: inline-block; }
    :global(.form-inline) :global(.input-group),
    :global(.form-inline) :global(.custom-select) {
      width: auto; }
    :global(.form-inline) :global(.form-check) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    :global(.form-inline) :global(.form-check-input) {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    :global(.form-inline) :global(.custom-control) {
      align-items: center;
      justify-content: center; }
    :global(.form-inline) :global(.custom-control-label) {
      margin-bottom: 0; } }

:global(.btn) {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    :global(.btn) {
      transition: none; } }
  :global(.btn:hover) {
    color: #212529;
    text-decoration: none; }
  :global(.btn:focus), :global(.btn.focus) {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }
  :global(.btn.disabled), :global(.btn:disabled) {
    opacity: 0.65; }
  :global(.btn:not(:disabled):not(.disabled)) {
    cursor: pointer; }

:global(a.btn.disabled),
:global(fieldset:disabled) :global(a.btn) {
  pointer-events: none; }

:global(.btn-primary) {
  color: #fff;
  background-color: #1db954;
  border-color: #1db954; }
  :global(.btn-primary:hover) {
    color: #fff;
    background-color: #189845;
    border-color: #168d40; }
  :global(.btn-primary:focus), :global(.btn-primary.focus) {
    color: #fff;
    background-color: #189845;
    border-color: #168d40;
    box-shadow: 0 0 0 0.2rem rgba(63, 196, 110, 0.5); }
  :global(.btn-primary.disabled), :global(.btn-primary:disabled) {
    color: #fff;
    background-color: #1db954;
    border-color: #1db954; }
  :global(.btn-primary:not(:disabled):not(.disabled):active), :global(.btn-primary:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-primary.dropdown-toggle) {
    color: #fff;
    background-color: #168d40;
    border-color: #14823b; }
    :global(.btn-primary:not(:disabled):not(.disabled):active:focus), :global(.btn-primary:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-primary.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(63, 196, 110, 0.5); }

:global(.btn-secondary) {
  color: #fff;
  background-color: #4a5759;
  border-color: #4a5759; }
  :global(.btn-secondary:hover) {
    color: #fff;
    background-color: #394344;
    border-color: #333c3d; }
  :global(.btn-secondary:focus), :global(.btn-secondary.focus) {
    color: #fff;
    background-color: #394344;
    border-color: #333c3d;
    box-shadow: 0 0 0 0.2rem rgba(101, 112, 114, 0.5); }
  :global(.btn-secondary.disabled), :global(.btn-secondary:disabled) {
    color: #fff;
    background-color: #4a5759;
    border-color: #4a5759; }
  :global(.btn-secondary:not(:disabled):not(.disabled):active), :global(.btn-secondary:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-secondary.dropdown-toggle) {
    color: #fff;
    background-color: #333c3d;
    border-color: #2d3536; }
    :global(.btn-secondary:not(:disabled):not(.disabled):active:focus), :global(.btn-secondary:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-secondary.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(101, 112, 114, 0.5); }

:global(.btn-success) {
  color: #fff;
  background-color: #1db954;
  border-color: #1db954; }
  :global(.btn-success:hover) {
    color: #fff;
    background-color: #189845;
    border-color: #168d40; }
  :global(.btn-success:focus), :global(.btn-success.focus) {
    color: #fff;
    background-color: #189845;
    border-color: #168d40;
    box-shadow: 0 0 0 0.2rem rgba(63, 196, 110, 0.5); }
  :global(.btn-success.disabled), :global(.btn-success:disabled) {
    color: #fff;
    background-color: #1db954;
    border-color: #1db954; }
  :global(.btn-success:not(:disabled):not(.disabled):active), :global(.btn-success:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-success.dropdown-toggle) {
    color: #fff;
    background-color: #168d40;
    border-color: #14823b; }
    :global(.btn-success:not(:disabled):not(.disabled):active:focus), :global(.btn-success:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-success.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(63, 196, 110, 0.5); }

:global(.btn-info) {
  color: #fff;
  background-color: #4059ad;
  border-color: #4059ad; }
  :global(.btn-info:hover) {
    color: #fff;
    background-color: #364b91;
    border-color: #324688; }
  :global(.btn-info:focus), :global(.btn-info.focus) {
    color: #fff;
    background-color: #364b91;
    border-color: #324688;
    box-shadow: 0 0 0 0.2rem rgba(93, 114, 185, 0.5); }
  :global(.btn-info.disabled), :global(.btn-info:disabled) {
    color: #fff;
    background-color: #4059ad;
    border-color: #4059ad; }
  :global(.btn-info:not(:disabled):not(.disabled):active), :global(.btn-info:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-info.dropdown-toggle) {
    color: #fff;
    background-color: #324688;
    border-color: #2f417e; }
    :global(.btn-info:not(:disabled):not(.disabled):active:focus), :global(.btn-info:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-info.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(93, 114, 185, 0.5); }

:global(.btn-warning) {
  color: #212529;
  background-color: #f5d547;
  border-color: #f5d547; }
  :global(.btn-warning:hover) {
    color: #212529;
    background-color: #f3cd23;
    border-color: #f2ca17; }
  :global(.btn-warning:focus), :global(.btn-warning.focus) {
    color: #212529;
    background-color: #f3cd23;
    border-color: #f2ca17;
    box-shadow: 0 0 0 0.2rem rgba(213, 187, 67, 0.5); }
  :global(.btn-warning.disabled), :global(.btn-warning:disabled) {
    color: #212529;
    background-color: #f5d547;
    border-color: #f5d547; }
  :global(.btn-warning:not(:disabled):not(.disabled):active), :global(.btn-warning:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-warning.dropdown-toggle) {
    color: #212529;
    background-color: #f2ca17;
    border-color: #efc60d; }
    :global(.btn-warning:not(:disabled):not(.disabled):active:focus), :global(.btn-warning:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-warning.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(213, 187, 67, 0.5); }

:global(.btn-danger) {
  color: #fff;
  background-color: #f07167;
  border-color: #f07167; }
  :global(.btn-danger:hover) {
    color: #fff;
    background-color: #ed5044;
    border-color: #eb4639; }
  :global(.btn-danger:focus), :global(.btn-danger.focus) {
    color: #fff;
    background-color: #ed5044;
    border-color: #eb4639;
    box-shadow: 0 0 0 0.2rem rgba(242, 134, 126, 0.5); }
  :global(.btn-danger.disabled), :global(.btn-danger:disabled) {
    color: #fff;
    background-color: #f07167;
    border-color: #f07167; }
  :global(.btn-danger:not(:disabled):not(.disabled):active), :global(.btn-danger:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-danger.dropdown-toggle) {
    color: #fff;
    background-color: #eb4639;
    border-color: #ea3b2d; }
    :global(.btn-danger:not(:disabled):not(.disabled):active:focus), :global(.btn-danger:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-danger.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(242, 134, 126, 0.5); }

:global(.btn-light) {
  color: #212529;
  background-color: #dedbd2;
  border-color: #dedbd2; }
  :global(.btn-light:hover) {
    color: #212529;
    background-color: #cec9bc;
    border-color: #c8c3b5; }
  :global(.btn-light:focus), :global(.btn-light.focus) {
    color: #212529;
    background-color: #cec9bc;
    border-color: #c8c3b5;
    box-shadow: 0 0 0 0.2rem rgba(194, 192, 185, 0.5); }
  :global(.btn-light.disabled), :global(.btn-light:disabled) {
    color: #212529;
    background-color: #dedbd2;
    border-color: #dedbd2; }
  :global(.btn-light:not(:disabled):not(.disabled):active), :global(.btn-light:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-light.dropdown-toggle) {
    color: #212529;
    background-color: #c8c3b5;
    border-color: #c3bead; }
    :global(.btn-light:not(:disabled):not(.disabled):active:focus), :global(.btn-light:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-light.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(194, 192, 185, 0.5); }

:global(.btn-dark) {
  color: #fff;
  background-color: #020122;
  border-color: #020122; }
  :global(.btn-dark:hover) {
    color: #fff;
    background-color: black;
    border-color: black; }
  :global(.btn-dark:focus), :global(.btn-dark.focus) {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(40, 39, 67, 0.5); }
  :global(.btn-dark.disabled), :global(.btn-dark:disabled) {
    color: #fff;
    background-color: #020122;
    border-color: #020122; }
  :global(.btn-dark:not(:disabled):not(.disabled):active), :global(.btn-dark:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-dark.dropdown-toggle) {
    color: #fff;
    background-color: black;
    border-color: black; }
    :global(.btn-dark:not(:disabled):not(.disabled):active:focus), :global(.btn-dark:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-dark.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(40, 39, 67, 0.5); }

:global(.btn-outline-primary) {
  color: #1db954;
  border-color: #1db954; }
  :global(.btn-outline-primary:hover) {
    color: #fff;
    background-color: #1db954;
    border-color: #1db954; }
  :global(.btn-outline-primary:focus), :global(.btn-outline-primary.focus) {
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.5); }
  :global(.btn-outline-primary.disabled), :global(.btn-outline-primary:disabled) {
    color: #1db954;
    background-color: transparent; }
  :global(.btn-outline-primary:not(:disabled):not(.disabled):active), :global(.btn-outline-primary:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-outline-primary.dropdown-toggle) {
    color: #fff;
    background-color: #1db954;
    border-color: #1db954; }
    :global(.btn-outline-primary:not(:disabled):not(.disabled):active:focus), :global(.btn-outline-primary:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-outline-primary.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.5); }

:global(.btn-outline-secondary) {
  color: #4a5759;
  border-color: #4a5759; }
  :global(.btn-outline-secondary:hover) {
    color: #fff;
    background-color: #4a5759;
    border-color: #4a5759; }
  :global(.btn-outline-secondary:focus), :global(.btn-outline-secondary.focus) {
    box-shadow: 0 0 0 0.2rem rgba(74, 87, 89, 0.5); }
  :global(.btn-outline-secondary.disabled), :global(.btn-outline-secondary:disabled) {
    color: #4a5759;
    background-color: transparent; }
  :global(.btn-outline-secondary:not(:disabled):not(.disabled):active), :global(.btn-outline-secondary:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-outline-secondary.dropdown-toggle) {
    color: #fff;
    background-color: #4a5759;
    border-color: #4a5759; }
    :global(.btn-outline-secondary:not(:disabled):not(.disabled):active:focus), :global(.btn-outline-secondary:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-outline-secondary.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(74, 87, 89, 0.5); }

:global(.btn-outline-success) {
  color: #1db954;
  border-color: #1db954; }
  :global(.btn-outline-success:hover) {
    color: #fff;
    background-color: #1db954;
    border-color: #1db954; }
  :global(.btn-outline-success:focus), :global(.btn-outline-success.focus) {
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.5); }
  :global(.btn-outline-success.disabled), :global(.btn-outline-success:disabled) {
    color: #1db954;
    background-color: transparent; }
  :global(.btn-outline-success:not(:disabled):not(.disabled):active), :global(.btn-outline-success:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-outline-success.dropdown-toggle) {
    color: #fff;
    background-color: #1db954;
    border-color: #1db954; }
    :global(.btn-outline-success:not(:disabled):not(.disabled):active:focus), :global(.btn-outline-success:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-outline-success.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.5); }

:global(.btn-outline-info) {
  color: #4059ad;
  border-color: #4059ad; }
  :global(.btn-outline-info:hover) {
    color: #fff;
    background-color: #4059ad;
    border-color: #4059ad; }
  :global(.btn-outline-info:focus), :global(.btn-outline-info.focus) {
    box-shadow: 0 0 0 0.2rem rgba(64, 89, 173, 0.5); }
  :global(.btn-outline-info.disabled), :global(.btn-outline-info:disabled) {
    color: #4059ad;
    background-color: transparent; }
  :global(.btn-outline-info:not(:disabled):not(.disabled):active), :global(.btn-outline-info:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-outline-info.dropdown-toggle) {
    color: #fff;
    background-color: #4059ad;
    border-color: #4059ad; }
    :global(.btn-outline-info:not(:disabled):not(.disabled):active:focus), :global(.btn-outline-info:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-outline-info.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(64, 89, 173, 0.5); }

:global(.btn-outline-warning) {
  color: #f5d547;
  border-color: #f5d547; }
  :global(.btn-outline-warning:hover) {
    color: #212529;
    background-color: #f5d547;
    border-color: #f5d547; }
  :global(.btn-outline-warning:focus), :global(.btn-outline-warning.focus) {
    box-shadow: 0 0 0 0.2rem rgba(245, 213, 71, 0.5); }
  :global(.btn-outline-warning.disabled), :global(.btn-outline-warning:disabled) {
    color: #f5d547;
    background-color: transparent; }
  :global(.btn-outline-warning:not(:disabled):not(.disabled):active), :global(.btn-outline-warning:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-outline-warning.dropdown-toggle) {
    color: #212529;
    background-color: #f5d547;
    border-color: #f5d547; }
    :global(.btn-outline-warning:not(:disabled):not(.disabled):active:focus), :global(.btn-outline-warning:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-outline-warning.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(245, 213, 71, 0.5); }

:global(.btn-outline-danger) {
  color: #f07167;
  border-color: #f07167; }
  :global(.btn-outline-danger:hover) {
    color: #fff;
    background-color: #f07167;
    border-color: #f07167; }
  :global(.btn-outline-danger:focus), :global(.btn-outline-danger.focus) {
    box-shadow: 0 0 0 0.2rem rgba(240, 113, 103, 0.5); }
  :global(.btn-outline-danger.disabled), :global(.btn-outline-danger:disabled) {
    color: #f07167;
    background-color: transparent; }
  :global(.btn-outline-danger:not(:disabled):not(.disabled):active), :global(.btn-outline-danger:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-outline-danger.dropdown-toggle) {
    color: #fff;
    background-color: #f07167;
    border-color: #f07167; }
    :global(.btn-outline-danger:not(:disabled):not(.disabled):active:focus), :global(.btn-outline-danger:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-outline-danger.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(240, 113, 103, 0.5); }

:global(.btn-outline-light) {
  color: #dedbd2;
  border-color: #dedbd2; }
  :global(.btn-outline-light:hover) {
    color: #212529;
    background-color: #dedbd2;
    border-color: #dedbd2; }
  :global(.btn-outline-light:focus), :global(.btn-outline-light.focus) {
    box-shadow: 0 0 0 0.2rem rgba(222, 219, 210, 0.5); }
  :global(.btn-outline-light.disabled), :global(.btn-outline-light:disabled) {
    color: #dedbd2;
    background-color: transparent; }
  :global(.btn-outline-light:not(:disabled):not(.disabled):active), :global(.btn-outline-light:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-outline-light.dropdown-toggle) {
    color: #212529;
    background-color: #dedbd2;
    border-color: #dedbd2; }
    :global(.btn-outline-light:not(:disabled):not(.disabled):active:focus), :global(.btn-outline-light:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-outline-light.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(222, 219, 210, 0.5); }

:global(.btn-outline-dark) {
  color: #020122;
  border-color: #020122; }
  :global(.btn-outline-dark:hover) {
    color: #fff;
    background-color: #020122;
    border-color: #020122; }
  :global(.btn-outline-dark:focus), :global(.btn-outline-dark.focus) {
    box-shadow: 0 0 0 0.2rem rgba(2, 1, 34, 0.5); }
  :global(.btn-outline-dark.disabled), :global(.btn-outline-dark:disabled) {
    color: #020122;
    background-color: transparent; }
  :global(.btn-outline-dark:not(:disabled):not(.disabled):active), :global(.btn-outline-dark:not(:disabled):not(.disabled).active), :global(.show) > :global(.btn-outline-dark.dropdown-toggle) {
    color: #fff;
    background-color: #020122;
    border-color: #020122; }
    :global(.btn-outline-dark:not(:disabled):not(.disabled):active:focus), :global(.btn-outline-dark:not(:disabled):not(.disabled).active:focus), :global(.show) > :global(.btn-outline-dark.dropdown-toggle:focus) {
      box-shadow: 0 0 0 0.2rem rgba(2, 1, 34, 0.5); }

:global(.btn-link) {
  font-weight: 400;
  color: #1db954;
  text-decoration: none; }
  :global(.btn-link:hover) {
    color: #137736;
    text-decoration: underline; }
  :global(.btn-link:focus), :global(.btn-link.focus) {
    text-decoration: underline; }
  :global(.btn-link:disabled), :global(.btn-link.disabled) {
    color: #6c757d;
    pointer-events: none; }

:global(.btn-lg), :global(.btn-group-lg) > :global(.btn) {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 25px; }

:global(.btn-sm), :global(.btn-group-sm) > :global(.btn) {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 15px; }

:global(.btn-block) {
  display: block;
  width: 100%; }
  :global(.btn-block) + :global(.btn-block) {
    margin-top: 0.5rem; }

:global(input[type="submit"].btn-block),
:global(input[type="reset"].btn-block),
:global(input[type="button"].btn-block) {
  width: 100%; }

:global(.fade) {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    :global(.fade) {
      transition: none; } }
  :global(.fade:not(.show)) {
    opacity: 0; }

:global(.collapse:not(.show)) {
  display: none; }

:global(.collapsing) {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    :global(.collapsing) {
      transition: none; } }

:global(.dropup),
:global(.dropright),
:global(.dropdown),
:global(.dropleft) {
  position: relative; }

:global(.dropdown-toggle) {
  white-space: nowrap; }
  :global(.dropdown-toggle::after) {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  :global(.dropdown-toggle:empty::after) {
    margin-left: 0; }

:global(.dropdown-menu) {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 20px; }

:global(.dropdown-menu-left) {
  right: auto;
  left: 0; }

:global(.dropdown-menu-right) {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  :global(.dropdown-menu-sm-left) {
    right: auto;
    left: 0; }
  :global(.dropdown-menu-sm-right) {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  :global(.dropdown-menu-md-left) {
    right: auto;
    left: 0; }
  :global(.dropdown-menu-md-right) {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  :global(.dropdown-menu-lg-left) {
    right: auto;
    left: 0; }
  :global(.dropdown-menu-lg-right) {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  :global(.dropdown-menu-xl-left) {
    right: auto;
    left: 0; }
  :global(.dropdown-menu-xl-right) {
    right: 0;
    left: auto; } }

:global(.dropup) :global(.dropdown-menu) {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

:global(.dropup) :global(.dropdown-toggle::after) {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

:global(.dropup) :global(.dropdown-toggle:empty::after) {
  margin-left: 0; }

:global(.dropright) :global(.dropdown-menu) {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

:global(.dropright) :global(.dropdown-toggle::after) {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

:global(.dropright) :global(.dropdown-toggle:empty::after) {
  margin-left: 0; }

:global(.dropright) :global(.dropdown-toggle::after) {
  vertical-align: 0; }

:global(.dropleft) :global(.dropdown-menu) {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

:global(.dropleft) :global(.dropdown-toggle::after) {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

:global(.dropleft) :global(.dropdown-toggle::after) {
  display: none; }

:global(.dropleft) :global(.dropdown-toggle::before) {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

:global(.dropleft) :global(.dropdown-toggle:empty::after) {
  margin-left: 0; }

:global(.dropleft) :global(.dropdown-toggle::before) {
  vertical-align: 0; }

:global(.dropdown-menu[x-placement^="top"]), :global(.dropdown-menu[x-placement^="right"]), :global(.dropdown-menu[x-placement^="bottom"]), :global(.dropdown-menu[x-placement^="left"]) {
  right: auto;
  bottom: auto; }

:global(.dropdown-divider) {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

:global(.dropdown-item) {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  :global(.dropdown-item:hover), :global(.dropdown-item:focus) {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  :global(.dropdown-item.active), :global(.dropdown-item:active) {
    color: #fff;
    text-decoration: none;
    background-color: #1db954; }
  :global(.dropdown-item.disabled), :global(.dropdown-item:disabled) {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

:global(.dropdown-menu.show) {
  display: block; }

:global(.dropdown-header) {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

:global(.dropdown-item-text) {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

:global(.btn-group),
:global(.btn-group-vertical) {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  :global(.btn-group) > :global(.btn),
  :global(.btn-group-vertical) > :global(.btn) {
    position: relative;
    flex: 1 1 auto; }
    :global(.btn-group) > :global(.btn:hover),
    :global(.btn-group-vertical) > :global(.btn:hover) {
      z-index: 1; }
    :global(.btn-group) > :global(.btn:focus), :global(.btn-group) > :global(.btn:active), :global(.btn-group) > :global(.btn.active), :global(.btn-group-vertical) > :global(.btn:focus), :global(.btn-group-vertical) > :global(.btn:active), :global(.btn-group-vertical) > :global(.btn.active) {
      z-index: 1; }

:global(.btn-toolbar) {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  :global(.btn-toolbar) :global(.input-group) {
    width: auto; }

:global(.btn-group) > :global(.btn:not(:first-child)),
:global(.btn-group) > :global(.btn-group:not(:first-child)) {
  margin-left: -1px; }

:global(.btn-group) > :global(.btn:not(:last-child):not(.dropdown-toggle)),
:global(.btn-group) > :global(.btn-group:not(:last-child)) > :global(.btn) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

:global(.btn-group) > :global(.btn:not(:first-child)),
:global(.btn-group) > :global(.btn-group:not(:first-child)) > :global(.btn) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

:global(.dropdown-toggle-split) {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  :global(.dropdown-toggle-split::after),
  :global(.dropup) :global(.dropdown-toggle-split::after),
  :global(.dropright) :global(.dropdown-toggle-split::after) {
    margin-left: 0; }
  :global(.dropleft) :global(.dropdown-toggle-split::before) {
    margin-right: 0; }

:global(.btn-sm) + :global(.dropdown-toggle-split), :global(.btn-group-sm) > :global(.btn) + :global(.dropdown-toggle-split) {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

:global(.btn-lg) + :global(.dropdown-toggle-split), :global(.btn-group-lg) > :global(.btn) + :global(.dropdown-toggle-split) {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

:global(.btn-group-vertical) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  :global(.btn-group-vertical) > :global(.btn),
  :global(.btn-group-vertical) > :global(.btn-group) {
    width: 100%; }
  :global(.btn-group-vertical) > :global(.btn:not(:first-child)),
  :global(.btn-group-vertical) > :global(.btn-group:not(:first-child)) {
    margin-top: -1px; }
  :global(.btn-group-vertical) > :global(.btn:not(:last-child):not(.dropdown-toggle)),
  :global(.btn-group-vertical) > :global(.btn-group:not(:last-child)) > :global(.btn) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  :global(.btn-group-vertical) > :global(.btn:not(:first-child)),
  :global(.btn-group-vertical) > :global(.btn-group:not(:first-child)) > :global(.btn) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

:global(.btn-group-toggle) > :global(.btn),
:global(.btn-group-toggle) > :global(.btn-group) > :global(.btn) {
  margin-bottom: 0; }
  :global(.btn-group-toggle) > :global(.btn) :global(input[type="radio"]),
  :global(.btn-group-toggle) > :global(.btn) :global(input[type="checkbox"]),
  :global(.btn-group-toggle) > :global(.btn-group) > :global(.btn) :global(input[type="radio"]),
  :global(.btn-group-toggle) > :global(.btn-group) > :global(.btn) :global(input[type="checkbox"]) {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

:global(.input-group) {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  :global(.input-group) > :global(.form-control),
  :global(.input-group) > :global(.form-control-plaintext),
  :global(.input-group) > :global(.custom-select),
  :global(.input-group) > :global(.custom-file) {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    :global(.input-group) > :global(.form-control) + :global(.form-control),
    :global(.input-group) > :global(.form-control) + :global(.custom-select),
    :global(.input-group) > :global(.form-control) + :global(.custom-file),
    :global(.input-group) > :global(.form-control-plaintext) + :global(.form-control),
    :global(.input-group) > :global(.form-control-plaintext) + :global(.custom-select),
    :global(.input-group) > :global(.form-control-plaintext) + :global(.custom-file),
    :global(.input-group) > :global(.custom-select) + :global(.form-control),
    :global(.input-group) > :global(.custom-select) + :global(.custom-select),
    :global(.input-group) > :global(.custom-select) + :global(.custom-file),
    :global(.input-group) > :global(.custom-file) + :global(.form-control),
    :global(.input-group) > :global(.custom-file) + :global(.custom-select),
    :global(.input-group) > :global(.custom-file) + :global(.custom-file) {
      margin-left: -1px; }
  :global(.input-group) > :global(.form-control:focus),
  :global(.input-group) > :global(.custom-select:focus),
  :global(.input-group) > :global(.custom-file) :global(.custom-file-input:focus) ~ :global(.custom-file-label) {
    z-index: 3; }
  :global(.input-group) > :global(.custom-file) :global(.custom-file-input:focus) {
    z-index: 4; }
  :global(.input-group) > :global(.form-control:not(:first-child)),
  :global(.input-group) > :global(.custom-select:not(:first-child)) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  :global(.input-group) > :global(.custom-file) {
    display: flex;
    align-items: center; }
    :global(.input-group) > :global(.custom-file:not(:last-child)) :global(.custom-file-label),
    :global(.input-group) > :global(.custom-file:not(:first-child)) :global(.custom-file-label) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  :global(.input-group:not(.has-validation)) > :global(.form-control:not(:last-child)),
  :global(.input-group:not(.has-validation)) > :global(.custom-select:not(:last-child)),
  :global(.input-group:not(.has-validation)) > :global(.custom-file:not(:last-child)) :global(.custom-file-label::after) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  :global(.input-group.has-validation) > :global(.form-control:nth-last-child(n) +:global( 3)),
  :global(.input-group.has-validation) > :global(.custom-select:nth-last-child(n) +:global( 3)),
  :global(.input-group.has-validation) > :global(.custom-file:nth-last-child(n) +:global( 3)) :global(.custom-file-label::after) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

:global(.input-group-prepend),
:global(.input-group-append) {
  display: flex; }
  :global(.input-group-prepend) :global(.btn),
  :global(.input-group-append) :global(.btn) {
    position: relative;
    z-index: 2; }
    :global(.input-group-prepend) :global(.btn:focus),
    :global(.input-group-append) :global(.btn:focus) {
      z-index: 3; }
  :global(.input-group-prepend) :global(.btn) + :global(.btn),
  :global(.input-group-prepend) :global(.btn) + :global(.input-group-text),
  :global(.input-group-prepend) :global(.input-group-text) + :global(.input-group-text),
  :global(.input-group-prepend) :global(.input-group-text) + :global(.btn),
  :global(.input-group-append) :global(.btn) + :global(.btn),
  :global(.input-group-append) :global(.btn) + :global(.input-group-text),
  :global(.input-group-append) :global(.input-group-text) + :global(.input-group-text),
  :global(.input-group-append) :global(.input-group-text) + :global(.btn) {
    margin-left: -1px; }

:global(.input-group-prepend) {
  margin-right: -1px; }

:global(.input-group-append) {
  margin-left: -1px; }

:global(.input-group-text) {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 20px; }
  :global(.input-group-text) :global(input[type="radio"]),
  :global(.input-group-text) :global(input[type="checkbox"]) {
    margin-top: 0; }

:global(.input-group-lg) > :global(.form-control:not(textarea)),
:global(.input-group-lg) > :global(.custom-select) {
  height: calc(1.5em + 1rem + 2px); }

:global(.input-group-lg) > :global(.form-control),
:global(.input-group-lg) > :global(.custom-select),
:global(.input-group-lg) > :global(.input-group-prepend) > :global(.input-group-text),
:global(.input-group-lg) > :global(.input-group-append) > :global(.input-group-text),
:global(.input-group-lg) > :global(.input-group-prepend) > :global(.btn),
:global(.input-group-lg) > :global(.input-group-append) > :global(.btn) {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 25px; }

:global(.input-group-sm) > :global(.form-control:not(textarea)),
:global(.input-group-sm) > :global(.custom-select) {
  height: calc(1.5em + 0.5rem + 2px); }

:global(.input-group-sm) > :global(.form-control),
:global(.input-group-sm) > :global(.custom-select),
:global(.input-group-sm) > :global(.input-group-prepend) > :global(.input-group-text),
:global(.input-group-sm) > :global(.input-group-append) > :global(.input-group-text),
:global(.input-group-sm) > :global(.input-group-prepend) > :global(.btn),
:global(.input-group-sm) > :global(.input-group-append) > :global(.btn) {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 15px; }

:global(.input-group-lg) > :global(.custom-select),
:global(.input-group-sm) > :global(.custom-select) {
  padding-right: 1.75rem; }

:global(.input-group) > :global(.input-group-prepend) > :global(.btn),
:global(.input-group) > :global(.input-group-prepend) > :global(.input-group-text),
:global(.input-group:not(.has-validation)) > :global(.input-group-append:not(:last-child)) > :global(.btn),
:global(.input-group:not(.has-validation)) > :global(.input-group-append:not(:last-child)) > :global(.input-group-text),
:global(.input-group.has-validation) > :global(.input-group-append:nth-last-child(n) +:global( 3)) > :global(.btn),
:global(.input-group.has-validation) > :global(.input-group-append:nth-last-child(n) +:global( 3)) > :global(.input-group-text),
:global(.input-group) > :global(.input-group-append:last-child) > :global(.btn:not(:last-child):not(.dropdown-toggle)),
:global(.input-group) > :global(.input-group-append:last-child) > :global(.input-group-text:not(:last-child)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

:global(.input-group) > :global(.input-group-append) > :global(.btn),
:global(.input-group) > :global(.input-group-append) > :global(.input-group-text),
:global(.input-group) > :global(.input-group-prepend:not(:first-child)) > :global(.btn),
:global(.input-group) > :global(.input-group-prepend:not(:first-child)) > :global(.input-group-text),
:global(.input-group) > :global(.input-group-prepend:first-child) > :global(.btn:not(:first-child)),
:global(.input-group) > :global(.input-group-prepend:first-child) > :global(.input-group-text:not(:first-child)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

:global(.custom-control) {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

:global(.custom-control-inline) {
  display: inline-flex;
  margin-right: 1rem; }

:global(.custom-control-input) {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  :global(.custom-control-input:checked) ~ :global(.custom-control-label::before) {
    color: #fff;
    border-color: #1db954;
    background-color: #1db954; }
  :global(.custom-control-input:focus) ~ :global(.custom-control-label::before) {
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }
  :global(.custom-control-input:focus:not(:checked)) ~ :global(.custom-control-label::before) {
    border-color: #6de899; }
  :global(.custom-control-input:not(:disabled):active) ~ :global(.custom-control-label::before) {
    color: #fff;
    background-color: #99efb8;
    border-color: #99efb8; }
  :global(.custom-control-input[disabled]) ~ :global(.custom-control-label), :global(.custom-control-input:disabled) ~ :global(.custom-control-label) {
    color: #6c757d; }
    :global(.custom-control-input[disabled]) ~ :global(.custom-control-label::before), :global(.custom-control-input:disabled) ~ :global(.custom-control-label::before) {
      background-color: #e9ecef; }

:global(.custom-control-label) {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  :global(.custom-control-label::before) {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  :global(.custom-control-label::after) {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

:global(.custom-checkbox) :global(.custom-control-label::before) {
  border-radius: 20px; }

:global(.custom-checkbox) :global(.custom-control-input:checked) ~ :global(.custom-control-label::after) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

:global(.custom-checkbox) :global(.custom-control-input:indeterminate) ~ :global(.custom-control-label::before) {
  border-color: #1db954;
  background-color: #1db954; }

:global(.custom-checkbox) :global(.custom-control-input:indeterminate) ~ :global(.custom-control-label::after) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

:global(.custom-checkbox) :global(.custom-control-input:disabled:checked) ~ :global(.custom-control-label::before) {
  background-color: rgba(29, 185, 84, 0.5); }

:global(.custom-checkbox) :global(.custom-control-input:disabled:indeterminate) ~ :global(.custom-control-label::before) {
  background-color: rgba(29, 185, 84, 0.5); }

:global(.custom-radio) :global(.custom-control-label::before) {
  border-radius: 50%; }

:global(.custom-radio) :global(.custom-control-input:checked) ~ :global(.custom-control-label::after) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

:global(.custom-radio) :global(.custom-control-input:disabled:checked) ~ :global(.custom-control-label::before) {
  background-color: rgba(29, 185, 84, 0.5); }

:global(.custom-switch) {
  padding-left: 2.25rem; }
  :global(.custom-switch) :global(.custom-control-label::before) {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  :global(.custom-switch) :global(.custom-control-label::after) {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      :global(.custom-switch) :global(.custom-control-label::after) {
        transition: none; } }
  :global(.custom-switch) :global(.custom-control-input:checked) ~ :global(.custom-control-label::after) {
    background-color: #fff;
    transform: translateX(0.75rem); }
  :global(.custom-switch) :global(.custom-control-input:disabled:checked) ~ :global(.custom-control-label::before) {
    background-color: rgba(29, 185, 84, 0.5); }

:global(.custom-select) {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 20px;
  appearance: none; }
  :global(.custom-select:focus) {
    border-color: #6de899;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }
    :global(.custom-select:focus::-ms-value) {
      color: #495057;
      background-color: #fff; }
  :global(.custom-select[multiple]), :global(.custom-select[size]:not([size="1"])) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  :global(.custom-select:disabled) {
    color: #6c757d;
    background-color: #e9ecef; }
  :global(.custom-select::-ms-expand) {
    display: none; }
  :global(.custom-select:-moz-focusring) {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

:global(.custom-select-sm) {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

:global(.custom-select-lg) {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

:global(.custom-file) {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

:global(.custom-file-input) {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  :global(.custom-file-input:focus) ~ :global(.custom-file-label) {
    border-color: #6de899;
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }
  :global(.custom-file-input[disabled]) ~ :global(.custom-file-label),
  :global(.custom-file-input:disabled) ~ :global(.custom-file-label) {
    background-color: #e9ecef; }
  :global(.custom-file-input:lang(en)) ~ :global(.custom-file-label::after) {
    content: "Browse"; }
  :global(.custom-file-input) ~ :global(.custom-file-label[data-browse]::after) {
    content: attr(data-browse); }

:global(.custom-file-label) {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 20px; }
  :global(.custom-file-label::after) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 20px 20px 0; }

:global(.custom-range) {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  :global(.custom-range:focus) {
    outline: 0; }
    :global(.custom-range:focus::-webkit-slider-thumb) {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }
    :global(.custom-range:focus::-moz-range-thumb) {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }
    :global(.custom-range:focus::-ms-thumb) {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(29, 185, 84, 0.25); }
  :global(.custom-range::-moz-focus-outer) {
    border: 0; }
  :global(.custom-range::-webkit-slider-thumb) {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #1db954;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      :global(.custom-range::-webkit-slider-thumb) {
        transition: none; } }
    :global(.custom-range::-webkit-slider-thumb:active) {
      background-color: #99efb8; }
  :global(.custom-range::-webkit-slider-runnable-track) {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  :global(.custom-range::-moz-range-thumb) {
    width: 1rem;
    height: 1rem;
    background-color: #1db954;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      :global(.custom-range::-moz-range-thumb) {
        transition: none; } }
    :global(.custom-range::-moz-range-thumb:active) {
      background-color: #99efb8; }
  :global(.custom-range::-moz-range-track) {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  :global(.custom-range::-ms-thumb) {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #1db954;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      :global(.custom-range::-ms-thumb) {
        transition: none; } }
    :global(.custom-range::-ms-thumb:active) {
      background-color: #99efb8; }
  :global(.custom-range::-ms-track) {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  :global(.custom-range::-ms-fill-lower) {
    background-color: #dee2e6;
    border-radius: 1rem; }
  :global(.custom-range::-ms-fill-upper) {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  :global(.custom-range:disabled::-webkit-slider-thumb) {
    background-color: #adb5bd; }
  :global(.custom-range:disabled::-webkit-slider-runnable-track) {
    cursor: default; }
  :global(.custom-range:disabled::-moz-range-thumb) {
    background-color: #adb5bd; }
  :global(.custom-range:disabled::-moz-range-track) {
    cursor: default; }
  :global(.custom-range:disabled::-ms-thumb) {
    background-color: #adb5bd; }

:global(.custom-control-label::before),
:global(.custom-file-label),
:global(.custom-select) {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    :global(.custom-control-label::before),
    :global(.custom-file-label),
    :global(.custom-select) {
      transition: none; } }

:global(.nav) {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

:global(.nav-link) {
  display: block;
  padding: 0.5rem 1rem; }
  :global(.nav-link:hover), :global(.nav-link:focus) {
    text-decoration: none; }
  :global(.nav-link.disabled) {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

:global(.nav-tabs) {
  border-bottom: 1px solid #dee2e6; }
  :global(.nav-tabs) :global(.nav-link) {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; }
    :global(.nav-tabs) :global(.nav-link:hover), :global(.nav-tabs) :global(.nav-link:focus) {
      border-color: #e9ecef #e9ecef #dee2e6; }
    :global(.nav-tabs) :global(.nav-link.disabled) {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  :global(.nav-tabs) :global(.nav-link.active),
  :global(.nav-tabs) :global(.nav-item.show) :global(.nav-link) {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  :global(.nav-tabs) :global(.dropdown-menu) {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

:global(.nav-pills) :global(.nav-link) {
  border-radius: 20px; }

:global(.nav-pills) :global(.nav-link.active),
:global(.nav-pills) :global(.show) > :global(.nav-link) {
  color: #fff;
  background-color: #1db954; }

:global(.nav-fill) > :global(.nav-link),
:global(.nav-fill) :global(.nav-item) {
  flex: 1 1 auto;
  text-align: center; }

:global(.nav-justified) > :global(.nav-link),
:global(.nav-justified) :global(.nav-item) {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

:global(.tab-content) > :global(.tab-pane) {
  display: none; }

:global(.tab-content) > :global(.active) {
  display: block; }

:global(.navbar) {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  :global(.navbar) :global(.container),
  :global(.navbar) :global(.container-fluid),
  :global(.navbar) :global(.container-sm),
  :global(.navbar) :global(.container-md),
  :global(.navbar) :global(.container-lg),
  :global(.navbar) :global(.container-xl) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

:global(.navbar-brand) {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  :global(.navbar-brand:hover), :global(.navbar-brand:focus) {
    text-decoration: none; }

:global(.navbar-nav) {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  :global(.navbar-nav) :global(.nav-link) {
    padding-right: 0;
    padding-left: 0; }
  :global(.navbar-nav) :global(.dropdown-menu) {
    position: static;
    float: none; }

:global(.navbar-text) {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

:global(.navbar-collapse) {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

:global(.navbar-toggler) {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 20px; }
  :global(.navbar-toggler:hover), :global(.navbar-toggler:focus) {
    text-decoration: none; }

:global(.navbar-toggler-icon) {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

:global(.navbar-nav-scroll) {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  :global(.navbar-expand-sm) > :global(.container),
  :global(.navbar-expand-sm) > :global(.container-fluid),
  :global(.navbar-expand-sm) > :global(.container-sm),
  :global(.navbar-expand-sm) > :global(.container-md),
  :global(.navbar-expand-sm) > :global(.container-lg),
  :global(.navbar-expand-sm) > :global(.container-xl) {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  :global(.navbar-expand-sm) {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    :global(.navbar-expand-sm) :global(.navbar-nav) {
      flex-direction: row; }
      :global(.navbar-expand-sm) :global(.navbar-nav) :global(.dropdown-menu) {
        position: absolute; }
      :global(.navbar-expand-sm) :global(.navbar-nav) :global(.nav-link) {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    :global(.navbar-expand-sm) > :global(.container),
    :global(.navbar-expand-sm) > :global(.container-fluid),
    :global(.navbar-expand-sm) > :global(.container-sm),
    :global(.navbar-expand-sm) > :global(.container-md),
    :global(.navbar-expand-sm) > :global(.container-lg),
    :global(.navbar-expand-sm) > :global(.container-xl) {
      flex-wrap: nowrap; }
    :global(.navbar-expand-sm) :global(.navbar-nav-scroll) {
      overflow: visible; }
    :global(.navbar-expand-sm) :global(.navbar-collapse) {
      display: flex !important;
      flex-basis: auto; }
    :global(.navbar-expand-sm) :global(.navbar-toggler) {
      display: none; } }

@media (max-width: 767.98px) {
  :global(.navbar-expand-md) > :global(.container),
  :global(.navbar-expand-md) > :global(.container-fluid),
  :global(.navbar-expand-md) > :global(.container-sm),
  :global(.navbar-expand-md) > :global(.container-md),
  :global(.navbar-expand-md) > :global(.container-lg),
  :global(.navbar-expand-md) > :global(.container-xl) {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  :global(.navbar-expand-md) {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    :global(.navbar-expand-md) :global(.navbar-nav) {
      flex-direction: row; }
      :global(.navbar-expand-md) :global(.navbar-nav) :global(.dropdown-menu) {
        position: absolute; }
      :global(.navbar-expand-md) :global(.navbar-nav) :global(.nav-link) {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    :global(.navbar-expand-md) > :global(.container),
    :global(.navbar-expand-md) > :global(.container-fluid),
    :global(.navbar-expand-md) > :global(.container-sm),
    :global(.navbar-expand-md) > :global(.container-md),
    :global(.navbar-expand-md) > :global(.container-lg),
    :global(.navbar-expand-md) > :global(.container-xl) {
      flex-wrap: nowrap; }
    :global(.navbar-expand-md) :global(.navbar-nav-scroll) {
      overflow: visible; }
    :global(.navbar-expand-md) :global(.navbar-collapse) {
      display: flex !important;
      flex-basis: auto; }
    :global(.navbar-expand-md) :global(.navbar-toggler) {
      display: none; } }

@media (max-width: 991.98px) {
  :global(.navbar-expand-lg) > :global(.container),
  :global(.navbar-expand-lg) > :global(.container-fluid),
  :global(.navbar-expand-lg) > :global(.container-sm),
  :global(.navbar-expand-lg) > :global(.container-md),
  :global(.navbar-expand-lg) > :global(.container-lg),
  :global(.navbar-expand-lg) > :global(.container-xl) {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  :global(.navbar-expand-lg) {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    :global(.navbar-expand-lg) :global(.navbar-nav) {
      flex-direction: row; }
      :global(.navbar-expand-lg) :global(.navbar-nav) :global(.dropdown-menu) {
        position: absolute; }
      :global(.navbar-expand-lg) :global(.navbar-nav) :global(.nav-link) {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    :global(.navbar-expand-lg) > :global(.container),
    :global(.navbar-expand-lg) > :global(.container-fluid),
    :global(.navbar-expand-lg) > :global(.container-sm),
    :global(.navbar-expand-lg) > :global(.container-md),
    :global(.navbar-expand-lg) > :global(.container-lg),
    :global(.navbar-expand-lg) > :global(.container-xl) {
      flex-wrap: nowrap; }
    :global(.navbar-expand-lg) :global(.navbar-nav-scroll) {
      overflow: visible; }
    :global(.navbar-expand-lg) :global(.navbar-collapse) {
      display: flex !important;
      flex-basis: auto; }
    :global(.navbar-expand-lg) :global(.navbar-toggler) {
      display: none; } }

@media (max-width: 1199.98px) {
  :global(.navbar-expand-xl) > :global(.container),
  :global(.navbar-expand-xl) > :global(.container-fluid),
  :global(.navbar-expand-xl) > :global(.container-sm),
  :global(.navbar-expand-xl) > :global(.container-md),
  :global(.navbar-expand-xl) > :global(.container-lg),
  :global(.navbar-expand-xl) > :global(.container-xl) {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  :global(.navbar-expand-xl) {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    :global(.navbar-expand-xl) :global(.navbar-nav) {
      flex-direction: row; }
      :global(.navbar-expand-xl) :global(.navbar-nav) :global(.dropdown-menu) {
        position: absolute; }
      :global(.navbar-expand-xl) :global(.navbar-nav) :global(.nav-link) {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    :global(.navbar-expand-xl) > :global(.container),
    :global(.navbar-expand-xl) > :global(.container-fluid),
    :global(.navbar-expand-xl) > :global(.container-sm),
    :global(.navbar-expand-xl) > :global(.container-md),
    :global(.navbar-expand-xl) > :global(.container-lg),
    :global(.navbar-expand-xl) > :global(.container-xl) {
      flex-wrap: nowrap; }
    :global(.navbar-expand-xl) :global(.navbar-nav-scroll) {
      overflow: visible; }
    :global(.navbar-expand-xl) :global(.navbar-collapse) {
      display: flex !important;
      flex-basis: auto; }
    :global(.navbar-expand-xl) :global(.navbar-toggler) {
      display: none; } }

:global(.navbar-expand) {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  :global(.navbar-expand) > :global(.container),
  :global(.navbar-expand) > :global(.container-fluid),
  :global(.navbar-expand) > :global(.container-sm),
  :global(.navbar-expand) > :global(.container-md),
  :global(.navbar-expand) > :global(.container-lg),
  :global(.navbar-expand) > :global(.container-xl) {
    padding-right: 0;
    padding-left: 0; }
  :global(.navbar-expand) :global(.navbar-nav) {
    flex-direction: row; }
    :global(.navbar-expand) :global(.navbar-nav) :global(.dropdown-menu) {
      position: absolute; }
    :global(.navbar-expand) :global(.navbar-nav) :global(.nav-link) {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  :global(.navbar-expand) > :global(.container),
  :global(.navbar-expand) > :global(.container-fluid),
  :global(.navbar-expand) > :global(.container-sm),
  :global(.navbar-expand) > :global(.container-md),
  :global(.navbar-expand) > :global(.container-lg),
  :global(.navbar-expand) > :global(.container-xl) {
    flex-wrap: nowrap; }
  :global(.navbar-expand) :global(.navbar-nav-scroll) {
    overflow: visible; }
  :global(.navbar-expand) :global(.navbar-collapse) {
    display: flex !important;
    flex-basis: auto; }
  :global(.navbar-expand) :global(.navbar-toggler) {
    display: none; }

:global(.navbar-light) :global(.navbar-brand) {
  color: rgba(0, 0, 0, 0.9); }
  :global(.navbar-light) :global(.navbar-brand:hover), :global(.navbar-light) :global(.navbar-brand:focus) {
    color: rgba(0, 0, 0, 0.9); }

:global(.navbar-light) :global(.navbar-nav) :global(.nav-link) {
  color: rgba(0, 0, 0, 0.5); }
  :global(.navbar-light) :global(.navbar-nav) :global(.nav-link:hover), :global(.navbar-light) :global(.navbar-nav) :global(.nav-link:focus) {
    color: rgba(0, 0, 0, 0.7); }
  :global(.navbar-light) :global(.navbar-nav) :global(.nav-link.disabled) {
    color: rgba(0, 0, 0, 0.3); }

:global(.navbar-light) :global(.navbar-nav) :global(.show) > :global(.nav-link),
:global(.navbar-light) :global(.navbar-nav) :global(.active) > :global(.nav-link),
:global(.navbar-light) :global(.navbar-nav) :global(.nav-link.show),
:global(.navbar-light) :global(.navbar-nav) :global(.nav-link.active) {
  color: rgba(0, 0, 0, 0.9); }

:global(.navbar-light) :global(.navbar-toggler) {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

:global(.navbar-light) :global(.navbar-toggler-icon) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

:global(.navbar-light) :global(.navbar-text) {
  color: rgba(0, 0, 0, 0.5); }
  :global(.navbar-light) :global(.navbar-text) :global(a) {
    color: rgba(0, 0, 0, 0.9); }
    :global(.navbar-light) :global(.navbar-text) :global(a:hover), :global(.navbar-light) :global(.navbar-text) :global(a:focus) {
      color: rgba(0, 0, 0, 0.9); }

:global(.navbar-dark) :global(.navbar-brand) {
  color: #fff; }
  :global(.navbar-dark) :global(.navbar-brand:hover), :global(.navbar-dark) :global(.navbar-brand:focus) {
    color: #fff; }

:global(.navbar-dark) :global(.navbar-nav) :global(.nav-link) {
  color: rgba(255, 255, 255, 0.5); }
  :global(.navbar-dark) :global(.navbar-nav) :global(.nav-link:hover), :global(.navbar-dark) :global(.navbar-nav) :global(.nav-link:focus) {
    color: rgba(255, 255, 255, 0.75); }
  :global(.navbar-dark) :global(.navbar-nav) :global(.nav-link.disabled) {
    color: rgba(255, 255, 255, 0.25); }

:global(.navbar-dark) :global(.navbar-nav) :global(.show) > :global(.nav-link),
:global(.navbar-dark) :global(.navbar-nav) :global(.active) > :global(.nav-link),
:global(.navbar-dark) :global(.navbar-nav) :global(.nav-link.show),
:global(.navbar-dark) :global(.navbar-nav) :global(.nav-link.active) {
  color: #fff; }

:global(.navbar-dark) :global(.navbar-toggler) {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

:global(.navbar-dark) :global(.navbar-toggler-icon) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

:global(.navbar-dark) :global(.navbar-text) {
  color: rgba(255, 255, 255, 0.5); }
  :global(.navbar-dark) :global(.navbar-text) :global(a) {
    color: #fff; }
    :global(.navbar-dark) :global(.navbar-text) :global(a:hover), :global(.navbar-dark) :global(.navbar-text) :global(a:focus) {
      color: #fff; }

:global(.card) {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 20px; }
  :global(.card) > :global(hr) {
    margin-right: 0;
    margin-left: 0; }
  :global(.card) > :global(.list-group) {
    border-top: inherit;
    border-bottom: inherit; }
    :global(.card) > :global(.list-group:first-child) {
      border-top-width: 0;
      border-top-left-radius: 19px;
      border-top-right-radius: 19px; }
    :global(.card) > :global(.list-group:last-child) {
      border-bottom-width: 0;
      border-bottom-right-radius: 19px;
      border-bottom-left-radius: 19px; }
  :global(.card) > :global(.card-header) + :global(.list-group),
  :global(.card) > :global(.list-group) + :global(.card-footer) {
    border-top: 0; }

:global(.card-body) {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

:global(.card-title) {
  margin-bottom: 0.75rem; }

:global(.card-subtitle) {
  margin-top: -0.375rem;
  margin-bottom: 0; }

:global(.card-text:last-child) {
  margin-bottom: 0; }

:global(.card-link:hover) {
  text-decoration: none; }

:global(.card-link) + :global(.card-link) {
  margin-left: 1.25rem; }

:global(.card-header) {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  :global(.card-header:first-child) {
    border-radius: 19px 19px 0 0; }

:global(.card-footer) {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  :global(.card-footer:last-child) {
    border-radius: 0 0 19px 19px; }

:global(.card-header-tabs) {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

:global(.card-header-pills) {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

:global(.card-img-overlay) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 19px; }

:global(.card-img),
:global(.card-img-top),
:global(.card-img-bottom) {
  flex-shrink: 0;
  width: 100%; }

:global(.card-img),
:global(.card-img-top) {
  border-top-left-radius: 19px;
  border-top-right-radius: 19px; }

:global(.card-img),
:global(.card-img-bottom) {
  border-bottom-right-radius: 19px;
  border-bottom-left-radius: 19px; }

:global(.card-deck) :global(.card) {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  :global(.card-deck) {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    :global(.card-deck) :global(.card) {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

:global(.card-group) > :global(.card) {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  :global(.card-group) {
    display: flex;
    flex-flow: row wrap; }
    :global(.card-group) > :global(.card) {
      flex: 1 0 0%;
      margin-bottom: 0; }
      :global(.card-group) > :global(.card) + :global(.card) {
        margin-left: 0;
        border-left: 0; }
      :global(.card-group) > :global(.card:not(:last-child)) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        :global(.card-group) > :global(.card:not(:last-child)) :global(.card-img-top),
        :global(.card-group) > :global(.card:not(:last-child)) :global(.card-header) {
          border-top-right-radius: 0; }
        :global(.card-group) > :global(.card:not(:last-child)) :global(.card-img-bottom),
        :global(.card-group) > :global(.card:not(:last-child)) :global(.card-footer) {
          border-bottom-right-radius: 0; }
      :global(.card-group) > :global(.card:not(:first-child)) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        :global(.card-group) > :global(.card:not(:first-child)) :global(.card-img-top),
        :global(.card-group) > :global(.card:not(:first-child)) :global(.card-header) {
          border-top-left-radius: 0; }
        :global(.card-group) > :global(.card:not(:first-child)) :global(.card-img-bottom),
        :global(.card-group) > :global(.card:not(:first-child)) :global(.card-footer) {
          border-bottom-left-radius: 0; } }

:global(.card-columns) :global(.card) {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  :global(.card-columns) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    :global(.card-columns) :global(.card) {
      display: inline-block;
      width: 100%; } }

:global(.accordion) {
  overflow-anchor: none; }
  :global(.accordion) > :global(.card) {
    overflow: hidden; }
    :global(.accordion) > :global(.card:not(:last-of-type)) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    :global(.accordion) > :global(.card:not(:first-of-type)) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    :global(.accordion) > :global(.card) > :global(.card-header) {
      border-radius: 0;
      margin-bottom: -1px; }

:global(.badge) {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    :global(.badge) {
      transition: none; } }
  :global(a.badge:hover), :global(a.badge:focus) {
    text-decoration: none; }
  :global(.badge:empty) {
    display: none; }

:global(.btn) :global(.badge) {
  position: relative;
  top: -1px; }

:global(.badge-pill) {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

:global(.badge-primary) {
  color: #fff;
  background-color: #1db954; }
  :global(a.badge-primary:hover), :global(a.badge-primary:focus) {
    color: #fff;
    background-color: #168d40; }
  :global(a.badge-primary:focus), :global(a.badge-primary.focus) {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.5); }

:global(.badge-secondary) {
  color: #fff;
  background-color: #4a5759; }
  :global(a.badge-secondary:hover), :global(a.badge-secondary:focus) {
    color: #fff;
    background-color: #333c3d; }
  :global(a.badge-secondary:focus), :global(a.badge-secondary.focus) {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(74, 87, 89, 0.5); }

:global(.badge-success) {
  color: #fff;
  background-color: #1db954; }
  :global(a.badge-success:hover), :global(a.badge-success:focus) {
    color: #fff;
    background-color: #168d40; }
  :global(a.badge-success:focus), :global(a.badge-success.focus) {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.5); }

:global(.badge-info) {
  color: #fff;
  background-color: #4059ad; }
  :global(a.badge-info:hover), :global(a.badge-info:focus) {
    color: #fff;
    background-color: #324688; }
  :global(a.badge-info:focus), :global(a.badge-info.focus) {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(64, 89, 173, 0.5); }

:global(.badge-warning) {
  color: #212529;
  background-color: #f5d547; }
  :global(a.badge-warning:hover), :global(a.badge-warning:focus) {
    color: #212529;
    background-color: #f2ca17; }
  :global(a.badge-warning:focus), :global(a.badge-warning.focus) {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 213, 71, 0.5); }

:global(.badge-danger) {
  color: #fff;
  background-color: #f07167; }
  :global(a.badge-danger:hover), :global(a.badge-danger:focus) {
    color: #fff;
    background-color: #eb4639; }
  :global(a.badge-danger:focus), :global(a.badge-danger.focus) {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(240, 113, 103, 0.5); }

:global(.badge-light) {
  color: #212529;
  background-color: #dedbd2; }
  :global(a.badge-light:hover), :global(a.badge-light:focus) {
    color: #212529;
    background-color: #c8c3b5; }
  :global(a.badge-light:focus), :global(a.badge-light.focus) {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(222, 219, 210, 0.5); }

:global(.badge-dark) {
  color: #fff;
  background-color: #020122; }
  :global(a.badge-dark:hover), :global(a.badge-dark:focus) {
    color: #fff;
    background-color: black; }
  :global(a.badge-dark:focus), :global(a.badge-dark.focus) {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(2, 1, 34, 0.5); }

:global(.alert) {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 20px; }

:global(.alert-heading) {
  color: inherit; }

:global(.alert-link) {
  font-weight: 700; }

:global(.alert-dismissible) {
  padding-right: 4rem; }
  :global(.alert-dismissible) :global(.close) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

:global(.alert-primary) {
  color: #0f602c;
  background-color: #d2f1dd;
  border-color: #c0ebcf; }
  :global(.alert-primary) :global(hr) {
    border-top-color: #ade5c0; }
  :global(.alert-primary) :global(.alert-link) {
    color: #083418; }

:global(.alert-secondary) {
  color: #262d2e;
  background-color: #dbddde;
  border-color: #ccd0d1; }
  :global(.alert-secondary) :global(hr) {
    border-top-color: #bfc4c5; }
  :global(.alert-secondary) :global(.alert-link) {
    color: #0f1212; }

:global(.alert-success) {
  color: #0f602c;
  background-color: #d2f1dd;
  border-color: #c0ebcf; }
  :global(.alert-success) :global(hr) {
    border-top-color: #ade5c0; }
  :global(.alert-success) :global(.alert-link) {
    color: #083418; }

:global(.alert-info) {
  color: #212e5a;
  background-color: #d9deef;
  border-color: #cad1e8; }
  :global(.alert-info) :global(hr) {
    border-top-color: #b8c2e0; }
  :global(.alert-info) :global(.alert-link) {
    color: #131b35; }

:global(.alert-warning) {
  color: #7f6f25;
  background-color: #fdf7da;
  border-color: #fcf3cb; }
  :global(.alert-warning) :global(hr) {
    border-top-color: #fbedb3; }
  :global(.alert-warning) :global(.alert-link) {
    color: #584c19; }

:global(.alert-danger) {
  color: #7d3b36;
  background-color: #fce3e1;
  border-color: #fbd7d4; }
  :global(.alert-danger) :global(hr) {
    border-top-color: #f9c1bd; }
  :global(.alert-danger) :global(.alert-link) {
    color: #592a27; }

:global(.alert-light) {
  color: #73726d;
  background-color: #f8f8f6;
  border-color: #f6f5f2; }
  :global(.alert-light) :global(hr) {
    border-top-color: #ece9e3; }
  :global(.alert-light) :global(.alert-link) {
    color: #595854; }

:global(.alert-dark) {
  color: #010112;
  background-color: #ccccd3;
  border-color: #b8b8c1; }
  :global(.alert-dark) :global(hr) {
    border-top-color: #aaaab5; }
  :global(.alert-dark) :global(.alert-link) {
    color: black; }

:global(.list-group) {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 20px; }

:global(.list-group-item-action) {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  :global(.list-group-item-action:hover), :global(.list-group-item-action:focus) {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  :global(.list-group-item-action:active) {
    color: #212529;
    background-color: #e9ecef; }

:global(.list-group-item) {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  :global(.list-group-item:first-child) {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  :global(.list-group-item:last-child) {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  :global(.list-group-item.disabled), :global(.list-group-item:disabled) {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  :global(.list-group-item.active) {
    z-index: 2;
    color: #fff;
    background-color: #1db954;
    border-color: #1db954; }
  :global(.list-group-item) + :global(.list-group-item) {
    border-top-width: 0; }
    :global(.list-group-item) + :global(.list-group-item.active) {
      margin-top: -1px;
      border-top-width: 1px; }

:global(.list-group-horizontal) {
  flex-direction: row; }
  :global(.list-group-horizontal) > :global(.list-group-item:first-child) {
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0; }
  :global(.list-group-horizontal) > :global(.list-group-item:last-child) {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0; }
  :global(.list-group-horizontal) > :global(.list-group-item.active) {
    margin-top: 0; }
  :global(.list-group-horizontal) > :global(.list-group-item) + :global(.list-group-item) {
    border-top-width: 1px;
    border-left-width: 0; }
    :global(.list-group-horizontal) > :global(.list-group-item) + :global(.list-group-item.active) {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  :global(.list-group-horizontal-sm) {
    flex-direction: row; }
    :global(.list-group-horizontal-sm) > :global(.list-group-item:first-child) {
      border-bottom-left-radius: 20px;
      border-top-right-radius: 0; }
    :global(.list-group-horizontal-sm) > :global(.list-group-item:last-child) {
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0; }
    :global(.list-group-horizontal-sm) > :global(.list-group-item.active) {
      margin-top: 0; }
    :global(.list-group-horizontal-sm) > :global(.list-group-item) + :global(.list-group-item) {
      border-top-width: 1px;
      border-left-width: 0; }
      :global(.list-group-horizontal-sm) > :global(.list-group-item) + :global(.list-group-item.active) {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  :global(.list-group-horizontal-md) {
    flex-direction: row; }
    :global(.list-group-horizontal-md) > :global(.list-group-item:first-child) {
      border-bottom-left-radius: 20px;
      border-top-right-radius: 0; }
    :global(.list-group-horizontal-md) > :global(.list-group-item:last-child) {
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0; }
    :global(.list-group-horizontal-md) > :global(.list-group-item.active) {
      margin-top: 0; }
    :global(.list-group-horizontal-md) > :global(.list-group-item) + :global(.list-group-item) {
      border-top-width: 1px;
      border-left-width: 0; }
      :global(.list-group-horizontal-md) > :global(.list-group-item) + :global(.list-group-item.active) {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  :global(.list-group-horizontal-lg) {
    flex-direction: row; }
    :global(.list-group-horizontal-lg) > :global(.list-group-item:first-child) {
      border-bottom-left-radius: 20px;
      border-top-right-radius: 0; }
    :global(.list-group-horizontal-lg) > :global(.list-group-item:last-child) {
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0; }
    :global(.list-group-horizontal-lg) > :global(.list-group-item.active) {
      margin-top: 0; }
    :global(.list-group-horizontal-lg) > :global(.list-group-item) + :global(.list-group-item) {
      border-top-width: 1px;
      border-left-width: 0; }
      :global(.list-group-horizontal-lg) > :global(.list-group-item) + :global(.list-group-item.active) {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  :global(.list-group-horizontal-xl) {
    flex-direction: row; }
    :global(.list-group-horizontal-xl) > :global(.list-group-item:first-child) {
      border-bottom-left-radius: 20px;
      border-top-right-radius: 0; }
    :global(.list-group-horizontal-xl) > :global(.list-group-item:last-child) {
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0; }
    :global(.list-group-horizontal-xl) > :global(.list-group-item.active) {
      margin-top: 0; }
    :global(.list-group-horizontal-xl) > :global(.list-group-item) + :global(.list-group-item) {
      border-top-width: 1px;
      border-left-width: 0; }
      :global(.list-group-horizontal-xl) > :global(.list-group-item) + :global(.list-group-item.active) {
        margin-left: -1px;
        border-left-width: 1px; } }

:global(.list-group-flush) {
  border-radius: 0; }
  :global(.list-group-flush) > :global(.list-group-item) {
    border-width: 0 0 1px; }
    :global(.list-group-flush) > :global(.list-group-item:last-child) {
      border-bottom-width: 0; }

:global(.list-group-item-primary) {
  color: #0f602c;
  background-color: #c0ebcf; }
  :global(.list-group-item-primary.list-group-item-action:hover), :global(.list-group-item-primary.list-group-item-action:focus) {
    color: #0f602c;
    background-color: #ade5c0; }
  :global(.list-group-item-primary.list-group-item-action.active) {
    color: #fff;
    background-color: #0f602c;
    border-color: #0f602c; }

:global(.list-group-item-secondary) {
  color: #262d2e;
  background-color: #ccd0d1; }
  :global(.list-group-item-secondary.list-group-item-action:hover), :global(.list-group-item-secondary.list-group-item-action:focus) {
    color: #262d2e;
    background-color: #bfc4c5; }
  :global(.list-group-item-secondary.list-group-item-action.active) {
    color: #fff;
    background-color: #262d2e;
    border-color: #262d2e; }

:global(.list-group-item-success) {
  color: #0f602c;
  background-color: #c0ebcf; }
  :global(.list-group-item-success.list-group-item-action:hover), :global(.list-group-item-success.list-group-item-action:focus) {
    color: #0f602c;
    background-color: #ade5c0; }
  :global(.list-group-item-success.list-group-item-action.active) {
    color: #fff;
    background-color: #0f602c;
    border-color: #0f602c; }

:global(.list-group-item-info) {
  color: #212e5a;
  background-color: #cad1e8; }
  :global(.list-group-item-info.list-group-item-action:hover), :global(.list-group-item-info.list-group-item-action:focus) {
    color: #212e5a;
    background-color: #b8c2e0; }
  :global(.list-group-item-info.list-group-item-action.active) {
    color: #fff;
    background-color: #212e5a;
    border-color: #212e5a; }

:global(.list-group-item-warning) {
  color: #7f6f25;
  background-color: #fcf3cb; }
  :global(.list-group-item-warning.list-group-item-action:hover), :global(.list-group-item-warning.list-group-item-action:focus) {
    color: #7f6f25;
    background-color: #fbedb3; }
  :global(.list-group-item-warning.list-group-item-action.active) {
    color: #fff;
    background-color: #7f6f25;
    border-color: #7f6f25; }

:global(.list-group-item-danger) {
  color: #7d3b36;
  background-color: #fbd7d4; }
  :global(.list-group-item-danger.list-group-item-action:hover), :global(.list-group-item-danger.list-group-item-action:focus) {
    color: #7d3b36;
    background-color: #f9c1bd; }
  :global(.list-group-item-danger.list-group-item-action.active) {
    color: #fff;
    background-color: #7d3b36;
    border-color: #7d3b36; }

:global(.list-group-item-light) {
  color: #73726d;
  background-color: #f6f5f2; }
  :global(.list-group-item-light.list-group-item-action:hover), :global(.list-group-item-light.list-group-item-action:focus) {
    color: #73726d;
    background-color: #ece9e3; }
  :global(.list-group-item-light.list-group-item-action.active) {
    color: #fff;
    background-color: #73726d;
    border-color: #73726d; }

:global(.list-group-item-dark) {
  color: #010112;
  background-color: #b8b8c1; }
  :global(.list-group-item-dark.list-group-item-action:hover), :global(.list-group-item-dark.list-group-item-action:focus) {
    color: #010112;
    background-color: #aaaab5; }
  :global(.list-group-item-dark.list-group-item-action.active) {
    color: #fff;
    background-color: #010112;
    border-color: #010112; }

:global(.close) {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  :global(.close:hover) {
    color: #000;
    text-decoration: none; }
  :global(.close:not(:disabled):not(.disabled):hover), :global(.close:not(:disabled):not(.disabled):focus) {
    opacity: .75; }

:global(button.close) {
  padding: 0;
  background-color: transparent;
  border: 0; }

:global(a.close.disabled) {
  pointer-events: none; }

:global(.toast) {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  :global(.toast:not(:last-child)) {
    margin-bottom: 0.75rem; }
  :global(.toast.showing) {
    opacity: 1; }
  :global(.toast.show) {
    display: block;
    opacity: 1; }
  :global(.toast.hide) {
    display: none; }

:global(.toast-header) {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

:global(.toast-body) {
  padding: 0.75rem; }

:global(.modal-open) {
  overflow: hidden; }
  :global(.modal-open) :global(.modal) {
    overflow-x: hidden;
    overflow-y: auto; }

:global(.modal) {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

:global(.modal-dialog) {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  :global(.modal.fade) :global(.modal-dialog) {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      :global(.modal.fade) :global(.modal-dialog) {
        transition: none; } }
  :global(.modal.show) :global(.modal-dialog) {
    transform: none; }
  :global(.modal.modal-static) :global(.modal-dialog) {
    transform: scale(1.02); }

:global(.modal-dialog-scrollable) {
  display: flex;
  max-height: calc(100% - 1rem); }
  :global(.modal-dialog-scrollable) :global(.modal-content) {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  :global(.modal-dialog-scrollable) :global(.modal-header),
  :global(.modal-dialog-scrollable) :global(.modal-footer) {
    flex-shrink: 0; }
  :global(.modal-dialog-scrollable) :global(.modal-body) {
    overflow-y: auto; }

:global(.modal-dialog-centered) {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  :global(.modal-dialog-centered::before) {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  :global(.modal-dialog-centered.modal-dialog-scrollable) {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    :global(.modal-dialog-centered.modal-dialog-scrollable) :global(.modal-content) {
      max-height: none; }
    :global(.modal-dialog-centered.modal-dialog-scrollable::before) {
      content: none; }

:global(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  outline: 0; }

:global(.modal-backdrop) {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  :global(.modal-backdrop.fade) {
    opacity: 0; }
  :global(.modal-backdrop.show) {
    opacity: 0.5; }

:global(.modal-header) {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px; }
  :global(.modal-header) :global(.close) {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

:global(.modal-title) {
  margin-bottom: 0;
  line-height: 1.5; }

:global(.modal-body) {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

:global(.modal-footer) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px; }
  :global(.modal-footer) > :global(*) {
    margin: 0.25rem; }

:global(.modal-scrollbar-measure) {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  :global(.modal-dialog) {
    max-width: 500px;
    margin: 1.75rem auto; }
  :global(.modal-dialog-scrollable) {
    max-height: calc(100% - 3.5rem); }
    :global(.modal-dialog-scrollable) :global(.modal-content) {
      max-height: calc(100vh - 3.5rem); }
  :global(.modal-dialog-centered) {
    min-height: calc(100% - 3.5rem); }
    :global(.modal-dialog-centered::before) {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  :global(.modal-sm) {
    max-width: 300px; } }

@media (min-width: 992px) {
  :global(.modal-lg),
  :global(.modal-xl) {
    max-width: 800px; } }

@media (min-width: 1200px) {
  :global(.modal-xl) {
    max-width: 1140px; } }

@keyframes -global-spinner-border {
  to {
    transform: rotate(360deg); } }

:global(.spinner-border) {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

:global(.spinner-border-sm) {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes -global-spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

:global(.spinner-grow) {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

:global(.spinner-grow-sm) {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  :global(.spinner-border),
  :global(.spinner-grow) {
    animation-duration: 1.5s; } }

:global(.align-baseline) {
  vertical-align: baseline !important; }

:global(.align-top) {
  vertical-align: top !important; }

:global(.align-middle) {
  vertical-align: middle !important; }

:global(.align-bottom) {
  vertical-align: bottom !important; }

:global(.align-text-bottom) {
  vertical-align: text-bottom !important; }

:global(.align-text-top) {
  vertical-align: text-top !important; }

:global(.bg-primary) {
  background-color: #1db954 !important; }

:global(a.bg-primary:hover), :global(a.bg-primary:focus), :global(button.bg-primary:hover), :global(button.bg-primary:focus) {
  background-color: #168d40 !important; }

:global(.bg-secondary) {
  background-color: #4a5759 !important; }

:global(a.bg-secondary:hover), :global(a.bg-secondary:focus), :global(button.bg-secondary:hover), :global(button.bg-secondary:focus) {
  background-color: #333c3d !important; }

:global(.bg-success) {
  background-color: #1db954 !important; }

:global(a.bg-success:hover), :global(a.bg-success:focus), :global(button.bg-success:hover), :global(button.bg-success:focus) {
  background-color: #168d40 !important; }

:global(.bg-info) {
  background-color: #4059ad !important; }

:global(a.bg-info:hover), :global(a.bg-info:focus), :global(button.bg-info:hover), :global(button.bg-info:focus) {
  background-color: #324688 !important; }

:global(.bg-warning) {
  background-color: #f5d547 !important; }

:global(a.bg-warning:hover), :global(a.bg-warning:focus), :global(button.bg-warning:hover), :global(button.bg-warning:focus) {
  background-color: #f2ca17 !important; }

:global(.bg-danger) {
  background-color: #f07167 !important; }

:global(a.bg-danger:hover), :global(a.bg-danger:focus), :global(button.bg-danger:hover), :global(button.bg-danger:focus) {
  background-color: #eb4639 !important; }

:global(.bg-light) {
  background-color: #dedbd2 !important; }

:global(a.bg-light:hover), :global(a.bg-light:focus), :global(button.bg-light:hover), :global(button.bg-light:focus) {
  background-color: #c8c3b5 !important; }

:global(.bg-dark) {
  background-color: #020122 !important; }

:global(a.bg-dark:hover), :global(a.bg-dark:focus), :global(button.bg-dark:hover), :global(button.bg-dark:focus) {
  background-color: black !important; }

:global(.bg-white) {
  background-color: #fff !important; }

:global(.bg-transparent) {
  background-color: transparent !important; }

:global(.border) {
  border: 1px solid #dee2e6 !important; }

:global(.border-top) {
  border-top: 1px solid #dee2e6 !important; }

:global(.border-right) {
  border-right: 1px solid #dee2e6 !important; }

:global(.border-bottom) {
  border-bottom: 1px solid #dee2e6 !important; }

:global(.border-left) {
  border-left: 1px solid #dee2e6 !important; }

:global(.border-0) {
  border: 0 !important; }

:global(.border-top-0) {
  border-top: 0 !important; }

:global(.border-right-0) {
  border-right: 0 !important; }

:global(.border-bottom-0) {
  border-bottom: 0 !important; }

:global(.border-left-0) {
  border-left: 0 !important; }

:global(.border-primary) {
  border-color: #1db954 !important; }

:global(.border-secondary) {
  border-color: #4a5759 !important; }

:global(.border-success) {
  border-color: #1db954 !important; }

:global(.border-info) {
  border-color: #4059ad !important; }

:global(.border-warning) {
  border-color: #f5d547 !important; }

:global(.border-danger) {
  border-color: #f07167 !important; }

:global(.border-light) {
  border-color: #dedbd2 !important; }

:global(.border-dark) {
  border-color: #020122 !important; }

:global(.border-white) {
  border-color: #fff !important; }

:global(.rounded-sm) {
  border-radius: 15px !important; }

:global(.rounded) {
  border-radius: 20px !important; }

:global(.rounded-top) {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important; }

:global(.rounded-right) {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important; }

:global(.rounded-bottom) {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important; }

:global(.rounded-left) {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important; }

:global(.rounded-lg) {
  border-radius: 25px !important; }

:global(.rounded-circle) {
  border-radius: 50% !important; }

:global(.rounded-pill) {
  border-radius: 50rem !important; }

:global(.rounded-0) {
  border-radius: 0 !important; }

:global(.clearfix::after) {
  display: block;
  clear: both;
  content: ""; }

:global(.d-none) {
  display: none !important; }

:global(.d-inline) {
  display: inline !important; }

:global(.d-inline-block) {
  display: inline-block !important; }

:global(.d-block) {
  display: block !important; }

:global(.d-table) {
  display: table !important; }

:global(.d-table-row) {
  display: table-row !important; }

:global(.d-table-cell) {
  display: table-cell !important; }

:global(.d-flex) {
  display: flex !important; }

:global(.d-inline-flex) {
  display: inline-flex !important; }

@media (min-width: 576px) {
  :global(.d-sm-none) {
    display: none !important; }
  :global(.d-sm-inline) {
    display: inline !important; }
  :global(.d-sm-inline-block) {
    display: inline-block !important; }
  :global(.d-sm-block) {
    display: block !important; }
  :global(.d-sm-table) {
    display: table !important; }
  :global(.d-sm-table-row) {
    display: table-row !important; }
  :global(.d-sm-table-cell) {
    display: table-cell !important; }
  :global(.d-sm-flex) {
    display: flex !important; }
  :global(.d-sm-inline-flex) {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  :global(.d-md-none) {
    display: none !important; }
  :global(.d-md-inline) {
    display: inline !important; }
  :global(.d-md-inline-block) {
    display: inline-block !important; }
  :global(.d-md-block) {
    display: block !important; }
  :global(.d-md-table) {
    display: table !important; }
  :global(.d-md-table-row) {
    display: table-row !important; }
  :global(.d-md-table-cell) {
    display: table-cell !important; }
  :global(.d-md-flex) {
    display: flex !important; }
  :global(.d-md-inline-flex) {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  :global(.d-lg-none) {
    display: none !important; }
  :global(.d-lg-inline) {
    display: inline !important; }
  :global(.d-lg-inline-block) {
    display: inline-block !important; }
  :global(.d-lg-block) {
    display: block !important; }
  :global(.d-lg-table) {
    display: table !important; }
  :global(.d-lg-table-row) {
    display: table-row !important; }
  :global(.d-lg-table-cell) {
    display: table-cell !important; }
  :global(.d-lg-flex) {
    display: flex !important; }
  :global(.d-lg-inline-flex) {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  :global(.d-xl-none) {
    display: none !important; }
  :global(.d-xl-inline) {
    display: inline !important; }
  :global(.d-xl-inline-block) {
    display: inline-block !important; }
  :global(.d-xl-block) {
    display: block !important; }
  :global(.d-xl-table) {
    display: table !important; }
  :global(.d-xl-table-row) {
    display: table-row !important; }
  :global(.d-xl-table-cell) {
    display: table-cell !important; }
  :global(.d-xl-flex) {
    display: flex !important; }
  :global(.d-xl-inline-flex) {
    display: inline-flex !important; } }

@media print {
  :global(.d-print-none) {
    display: none !important; }
  :global(.d-print-inline) {
    display: inline !important; }
  :global(.d-print-inline-block) {
    display: inline-block !important; }
  :global(.d-print-block) {
    display: block !important; }
  :global(.d-print-table) {
    display: table !important; }
  :global(.d-print-table-row) {
    display: table-row !important; }
  :global(.d-print-table-cell) {
    display: table-cell !important; }
  :global(.d-print-flex) {
    display: flex !important; }
  :global(.d-print-inline-flex) {
    display: inline-flex !important; } }

:global(.embed-responsive) {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  :global(.embed-responsive::before) {
    display: block;
    content: ""; }
  :global(.embed-responsive) :global(.embed-responsive-item),
  :global(.embed-responsive) :global(iframe),
  :global(.embed-responsive) :global(embed),
  :global(.embed-responsive) :global(object),
  :global(.embed-responsive) :global(video) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

:global(.embed-responsive-21by9::before) {
  padding-top: 42.85714%; }

:global(.embed-responsive-16by9::before) {
  padding-top: 56.25%; }

:global(.embed-responsive-4by3::before) {
  padding-top: 75%; }

:global(.embed-responsive-1by1::before) {
  padding-top: 100%; }

:global(.flex-row) {
  flex-direction: row !important; }

:global(.flex-column) {
  flex-direction: column !important; }

:global(.flex-row-reverse) {
  flex-direction: row-reverse !important; }

:global(.flex-column-reverse) {
  flex-direction: column-reverse !important; }

:global(.flex-wrap) {
  flex-wrap: wrap !important; }

:global(.flex-nowrap) {
  flex-wrap: nowrap !important; }

:global(.flex-wrap-reverse) {
  flex-wrap: wrap-reverse !important; }

:global(.flex-fill) {
  flex: 1 1 auto !important; }

:global(.flex-grow-0) {
  flex-grow: 0 !important; }

:global(.flex-grow-1) {
  flex-grow: 1 !important; }

:global(.flex-shrink-0) {
  flex-shrink: 0 !important; }

:global(.flex-shrink-1) {
  flex-shrink: 1 !important; }

:global(.justify-content-start) {
  justify-content: flex-start !important; }

:global(.justify-content-end) {
  justify-content: flex-end !important; }

:global(.justify-content-center) {
  justify-content: center !important; }

:global(.justify-content-between) {
  justify-content: space-between !important; }

:global(.justify-content-around) {
  justify-content: space-around !important; }

:global(.align-items-start) {
  align-items: flex-start !important; }

:global(.align-items-end) {
  align-items: flex-end !important; }

:global(.align-items-center) {
  align-items: center !important; }

:global(.align-items-baseline) {
  align-items: baseline !important; }

:global(.align-items-stretch) {
  align-items: stretch !important; }

:global(.align-content-start) {
  align-content: flex-start !important; }

:global(.align-content-end) {
  align-content: flex-end !important; }

:global(.align-content-center) {
  align-content: center !important; }

:global(.align-content-between) {
  align-content: space-between !important; }

:global(.align-content-around) {
  align-content: space-around !important; }

:global(.align-content-stretch) {
  align-content: stretch !important; }

:global(.align-self-auto) {
  align-self: auto !important; }

:global(.align-self-start) {
  align-self: flex-start !important; }

:global(.align-self-end) {
  align-self: flex-end !important; }

:global(.align-self-center) {
  align-self: center !important; }

:global(.align-self-baseline) {
  align-self: baseline !important; }

:global(.align-self-stretch) {
  align-self: stretch !important; }

@media (min-width: 576px) {
  :global(.flex-sm-row) {
    flex-direction: row !important; }
  :global(.flex-sm-column) {
    flex-direction: column !important; }
  :global(.flex-sm-row-reverse) {
    flex-direction: row-reverse !important; }
  :global(.flex-sm-column-reverse) {
    flex-direction: column-reverse !important; }
  :global(.flex-sm-wrap) {
    flex-wrap: wrap !important; }
  :global(.flex-sm-nowrap) {
    flex-wrap: nowrap !important; }
  :global(.flex-sm-wrap-reverse) {
    flex-wrap: wrap-reverse !important; }
  :global(.flex-sm-fill) {
    flex: 1 1 auto !important; }
  :global(.flex-sm-grow-0) {
    flex-grow: 0 !important; }
  :global(.flex-sm-grow-1) {
    flex-grow: 1 !important; }
  :global(.flex-sm-shrink-0) {
    flex-shrink: 0 !important; }
  :global(.flex-sm-shrink-1) {
    flex-shrink: 1 !important; }
  :global(.justify-content-sm-start) {
    justify-content: flex-start !important; }
  :global(.justify-content-sm-end) {
    justify-content: flex-end !important; }
  :global(.justify-content-sm-center) {
    justify-content: center !important; }
  :global(.justify-content-sm-between) {
    justify-content: space-between !important; }
  :global(.justify-content-sm-around) {
    justify-content: space-around !important; }
  :global(.align-items-sm-start) {
    align-items: flex-start !important; }
  :global(.align-items-sm-end) {
    align-items: flex-end !important; }
  :global(.align-items-sm-center) {
    align-items: center !important; }
  :global(.align-items-sm-baseline) {
    align-items: baseline !important; }
  :global(.align-items-sm-stretch) {
    align-items: stretch !important; }
  :global(.align-content-sm-start) {
    align-content: flex-start !important; }
  :global(.align-content-sm-end) {
    align-content: flex-end !important; }
  :global(.align-content-sm-center) {
    align-content: center !important; }
  :global(.align-content-sm-between) {
    align-content: space-between !important; }
  :global(.align-content-sm-around) {
    align-content: space-around !important; }
  :global(.align-content-sm-stretch) {
    align-content: stretch !important; }
  :global(.align-self-sm-auto) {
    align-self: auto !important; }
  :global(.align-self-sm-start) {
    align-self: flex-start !important; }
  :global(.align-self-sm-end) {
    align-self: flex-end !important; }
  :global(.align-self-sm-center) {
    align-self: center !important; }
  :global(.align-self-sm-baseline) {
    align-self: baseline !important; }
  :global(.align-self-sm-stretch) {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  :global(.flex-md-row) {
    flex-direction: row !important; }
  :global(.flex-md-column) {
    flex-direction: column !important; }
  :global(.flex-md-row-reverse) {
    flex-direction: row-reverse !important; }
  :global(.flex-md-column-reverse) {
    flex-direction: column-reverse !important; }
  :global(.flex-md-wrap) {
    flex-wrap: wrap !important; }
  :global(.flex-md-nowrap) {
    flex-wrap: nowrap !important; }
  :global(.flex-md-wrap-reverse) {
    flex-wrap: wrap-reverse !important; }
  :global(.flex-md-fill) {
    flex: 1 1 auto !important; }
  :global(.flex-md-grow-0) {
    flex-grow: 0 !important; }
  :global(.flex-md-grow-1) {
    flex-grow: 1 !important; }
  :global(.flex-md-shrink-0) {
    flex-shrink: 0 !important; }
  :global(.flex-md-shrink-1) {
    flex-shrink: 1 !important; }
  :global(.justify-content-md-start) {
    justify-content: flex-start !important; }
  :global(.justify-content-md-end) {
    justify-content: flex-end !important; }
  :global(.justify-content-md-center) {
    justify-content: center !important; }
  :global(.justify-content-md-between) {
    justify-content: space-between !important; }
  :global(.justify-content-md-around) {
    justify-content: space-around !important; }
  :global(.align-items-md-start) {
    align-items: flex-start !important; }
  :global(.align-items-md-end) {
    align-items: flex-end !important; }
  :global(.align-items-md-center) {
    align-items: center !important; }
  :global(.align-items-md-baseline) {
    align-items: baseline !important; }
  :global(.align-items-md-stretch) {
    align-items: stretch !important; }
  :global(.align-content-md-start) {
    align-content: flex-start !important; }
  :global(.align-content-md-end) {
    align-content: flex-end !important; }
  :global(.align-content-md-center) {
    align-content: center !important; }
  :global(.align-content-md-between) {
    align-content: space-between !important; }
  :global(.align-content-md-around) {
    align-content: space-around !important; }
  :global(.align-content-md-stretch) {
    align-content: stretch !important; }
  :global(.align-self-md-auto) {
    align-self: auto !important; }
  :global(.align-self-md-start) {
    align-self: flex-start !important; }
  :global(.align-self-md-end) {
    align-self: flex-end !important; }
  :global(.align-self-md-center) {
    align-self: center !important; }
  :global(.align-self-md-baseline) {
    align-self: baseline !important; }
  :global(.align-self-md-stretch) {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  :global(.flex-lg-row) {
    flex-direction: row !important; }
  :global(.flex-lg-column) {
    flex-direction: column !important; }
  :global(.flex-lg-row-reverse) {
    flex-direction: row-reverse !important; }
  :global(.flex-lg-column-reverse) {
    flex-direction: column-reverse !important; }
  :global(.flex-lg-wrap) {
    flex-wrap: wrap !important; }
  :global(.flex-lg-nowrap) {
    flex-wrap: nowrap !important; }
  :global(.flex-lg-wrap-reverse) {
    flex-wrap: wrap-reverse !important; }
  :global(.flex-lg-fill) {
    flex: 1 1 auto !important; }
  :global(.flex-lg-grow-0) {
    flex-grow: 0 !important; }
  :global(.flex-lg-grow-1) {
    flex-grow: 1 !important; }
  :global(.flex-lg-shrink-0) {
    flex-shrink: 0 !important; }
  :global(.flex-lg-shrink-1) {
    flex-shrink: 1 !important; }
  :global(.justify-content-lg-start) {
    justify-content: flex-start !important; }
  :global(.justify-content-lg-end) {
    justify-content: flex-end !important; }
  :global(.justify-content-lg-center) {
    justify-content: center !important; }
  :global(.justify-content-lg-between) {
    justify-content: space-between !important; }
  :global(.justify-content-lg-around) {
    justify-content: space-around !important; }
  :global(.align-items-lg-start) {
    align-items: flex-start !important; }
  :global(.align-items-lg-end) {
    align-items: flex-end !important; }
  :global(.align-items-lg-center) {
    align-items: center !important; }
  :global(.align-items-lg-baseline) {
    align-items: baseline !important; }
  :global(.align-items-lg-stretch) {
    align-items: stretch !important; }
  :global(.align-content-lg-start) {
    align-content: flex-start !important; }
  :global(.align-content-lg-end) {
    align-content: flex-end !important; }
  :global(.align-content-lg-center) {
    align-content: center !important; }
  :global(.align-content-lg-between) {
    align-content: space-between !important; }
  :global(.align-content-lg-around) {
    align-content: space-around !important; }
  :global(.align-content-lg-stretch) {
    align-content: stretch !important; }
  :global(.align-self-lg-auto) {
    align-self: auto !important; }
  :global(.align-self-lg-start) {
    align-self: flex-start !important; }
  :global(.align-self-lg-end) {
    align-self: flex-end !important; }
  :global(.align-self-lg-center) {
    align-self: center !important; }
  :global(.align-self-lg-baseline) {
    align-self: baseline !important; }
  :global(.align-self-lg-stretch) {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  :global(.flex-xl-row) {
    flex-direction: row !important; }
  :global(.flex-xl-column) {
    flex-direction: column !important; }
  :global(.flex-xl-row-reverse) {
    flex-direction: row-reverse !important; }
  :global(.flex-xl-column-reverse) {
    flex-direction: column-reverse !important; }
  :global(.flex-xl-wrap) {
    flex-wrap: wrap !important; }
  :global(.flex-xl-nowrap) {
    flex-wrap: nowrap !important; }
  :global(.flex-xl-wrap-reverse) {
    flex-wrap: wrap-reverse !important; }
  :global(.flex-xl-fill) {
    flex: 1 1 auto !important; }
  :global(.flex-xl-grow-0) {
    flex-grow: 0 !important; }
  :global(.flex-xl-grow-1) {
    flex-grow: 1 !important; }
  :global(.flex-xl-shrink-0) {
    flex-shrink: 0 !important; }
  :global(.flex-xl-shrink-1) {
    flex-shrink: 1 !important; }
  :global(.justify-content-xl-start) {
    justify-content: flex-start !important; }
  :global(.justify-content-xl-end) {
    justify-content: flex-end !important; }
  :global(.justify-content-xl-center) {
    justify-content: center !important; }
  :global(.justify-content-xl-between) {
    justify-content: space-between !important; }
  :global(.justify-content-xl-around) {
    justify-content: space-around !important; }
  :global(.align-items-xl-start) {
    align-items: flex-start !important; }
  :global(.align-items-xl-end) {
    align-items: flex-end !important; }
  :global(.align-items-xl-center) {
    align-items: center !important; }
  :global(.align-items-xl-baseline) {
    align-items: baseline !important; }
  :global(.align-items-xl-stretch) {
    align-items: stretch !important; }
  :global(.align-content-xl-start) {
    align-content: flex-start !important; }
  :global(.align-content-xl-end) {
    align-content: flex-end !important; }
  :global(.align-content-xl-center) {
    align-content: center !important; }
  :global(.align-content-xl-between) {
    align-content: space-between !important; }
  :global(.align-content-xl-around) {
    align-content: space-around !important; }
  :global(.align-content-xl-stretch) {
    align-content: stretch !important; }
  :global(.align-self-xl-auto) {
    align-self: auto !important; }
  :global(.align-self-xl-start) {
    align-self: flex-start !important; }
  :global(.align-self-xl-end) {
    align-self: flex-end !important; }
  :global(.align-self-xl-center) {
    align-self: center !important; }
  :global(.align-self-xl-baseline) {
    align-self: baseline !important; }
  :global(.align-self-xl-stretch) {
    align-self: stretch !important; } }

:global(.float-left) {
  float: left !important; }

:global(.float-right) {
  float: right !important; }

:global(.float-none) {
  float: none !important; }

@media (min-width: 576px) {
  :global(.float-sm-left) {
    float: left !important; }
  :global(.float-sm-right) {
    float: right !important; }
  :global(.float-sm-none) {
    float: none !important; } }

@media (min-width: 768px) {
  :global(.float-md-left) {
    float: left !important; }
  :global(.float-md-right) {
    float: right !important; }
  :global(.float-md-none) {
    float: none !important; } }

@media (min-width: 992px) {
  :global(.float-lg-left) {
    float: left !important; }
  :global(.float-lg-right) {
    float: right !important; }
  :global(.float-lg-none) {
    float: none !important; } }

@media (min-width: 1200px) {
  :global(.float-xl-left) {
    float: left !important; }
  :global(.float-xl-right) {
    float: right !important; }
  :global(.float-xl-none) {
    float: none !important; } }

:global(.user-select-all) {
  user-select: all !important; }

:global(.user-select-auto) {
  user-select: auto !important; }

:global(.user-select-none) {
  user-select: none !important; }

:global(.overflow-auto) {
  overflow: auto !important; }

:global(.overflow-hidden) {
  overflow: hidden !important; }

:global(.position-static) {
  position: static !important; }

:global(.position-relative) {
  position: relative !important; }

:global(.position-absolute) {
  position: absolute !important; }

:global(.position-fixed) {
  position: fixed !important; }

:global(.position-sticky) {
  position: sticky !important; }

:global(.fixed-top) {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

:global(.fixed-bottom) {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  :global(.sticky-top) {
    position: sticky;
    top: 0;
    z-index: 1020; } }

:global(.sr-only) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

:global(.sr-only-focusable:active), :global(.sr-only-focusable:focus) {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

:global(.shadow-sm) {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

:global(.shadow) {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

:global(.shadow-lg) {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

:global(.shadow-none) {
  box-shadow: none !important; }

:global(.w-25) {
  width: 25% !important; }

:global(.w-50) {
  width: 50% !important; }

:global(.w-75) {
  width: 75% !important; }

:global(.w-100) {
  width: 100% !important; }

:global(.w-auto) {
  width: auto !important; }

:global(.h-25) {
  height: 25% !important; }

:global(.h-50) {
  height: 50% !important; }

:global(.h-75) {
  height: 75% !important; }

:global(.h-100) {
  height: 100% !important; }

:global(.h-auto) {
  height: auto !important; }

:global(.mw-100) {
  max-width: 100% !important; }

:global(.mh-100) {
  max-height: 100% !important; }

:global(.min-vw-100) {
  min-width: 100vw !important; }

:global(.min-vh-100) {
  min-height: 100vh !important; }

:global(.vw-100) {
  width: 100vw !important; }

:global(.vh-100) {
  height: 100vh !important; }

:global(.m-0) {
  margin: 0 !important; }

:global(.mt-0),
:global(.my-0) {
  margin-top: 0 !important; }

:global(.mr-0),
:global(.mx-0) {
  margin-right: 0 !important; }

:global(.mb-0),
:global(.my-0) {
  margin-bottom: 0 !important; }

:global(.ml-0),
:global(.mx-0) {
  margin-left: 0 !important; }

:global(.m-1) {
  margin: 0.25rem !important; }

:global(.mt-1),
:global(.my-1) {
  margin-top: 0.25rem !important; }

:global(.mr-1),
:global(.mx-1) {
  margin-right: 0.25rem !important; }

:global(.mb-1),
:global(.my-1) {
  margin-bottom: 0.25rem !important; }

:global(.ml-1),
:global(.mx-1) {
  margin-left: 0.25rem !important; }

:global(.m-2) {
  margin: 0.5rem !important; }

:global(.mt-2),
:global(.my-2) {
  margin-top: 0.5rem !important; }

:global(.mr-2),
:global(.mx-2) {
  margin-right: 0.5rem !important; }

:global(.mb-2),
:global(.my-2) {
  margin-bottom: 0.5rem !important; }

:global(.ml-2),
:global(.mx-2) {
  margin-left: 0.5rem !important; }

:global(.m-3) {
  margin: 1rem !important; }

:global(.mt-3),
:global(.my-3) {
  margin-top: 1rem !important; }

:global(.mr-3),
:global(.mx-3) {
  margin-right: 1rem !important; }

:global(.mb-3),
:global(.my-3) {
  margin-bottom: 1rem !important; }

:global(.ml-3),
:global(.mx-3) {
  margin-left: 1rem !important; }

:global(.m-4) {
  margin: 1.5rem !important; }

:global(.mt-4),
:global(.my-4) {
  margin-top: 1.5rem !important; }

:global(.mr-4),
:global(.mx-4) {
  margin-right: 1.5rem !important; }

:global(.mb-4),
:global(.my-4) {
  margin-bottom: 1.5rem !important; }

:global(.ml-4),
:global(.mx-4) {
  margin-left: 1.5rem !important; }

:global(.m-5) {
  margin: 3rem !important; }

:global(.mt-5),
:global(.my-5) {
  margin-top: 3rem !important; }

:global(.mr-5),
:global(.mx-5) {
  margin-right: 3rem !important; }

:global(.mb-5),
:global(.my-5) {
  margin-bottom: 3rem !important; }

:global(.ml-5),
:global(.mx-5) {
  margin-left: 3rem !important; }

:global(.p-0) {
  padding: 0 !important; }

:global(.pt-0),
:global(.py-0) {
  padding-top: 0 !important; }

:global(.pr-0),
:global(.px-0) {
  padding-right: 0 !important; }

:global(.pb-0),
:global(.py-0) {
  padding-bottom: 0 !important; }

:global(.pl-0),
:global(.px-0) {
  padding-left: 0 !important; }

:global(.p-1) {
  padding: 0.25rem !important; }

:global(.pt-1),
:global(.py-1) {
  padding-top: 0.25rem !important; }

:global(.pr-1),
:global(.px-1) {
  padding-right: 0.25rem !important; }

:global(.pb-1),
:global(.py-1) {
  padding-bottom: 0.25rem !important; }

:global(.pl-1),
:global(.px-1) {
  padding-left: 0.25rem !important; }

:global(.p-2) {
  padding: 0.5rem !important; }

:global(.pt-2),
:global(.py-2) {
  padding-top: 0.5rem !important; }

:global(.pr-2),
:global(.px-2) {
  padding-right: 0.5rem !important; }

:global(.pb-2),
:global(.py-2) {
  padding-bottom: 0.5rem !important; }

:global(.pl-2),
:global(.px-2) {
  padding-left: 0.5rem !important; }

:global(.p-3) {
  padding: 1rem !important; }

:global(.pt-3),
:global(.py-3) {
  padding-top: 1rem !important; }

:global(.pr-3),
:global(.px-3) {
  padding-right: 1rem !important; }

:global(.pb-3),
:global(.py-3) {
  padding-bottom: 1rem !important; }

:global(.pl-3),
:global(.px-3) {
  padding-left: 1rem !important; }

:global(.p-4) {
  padding: 1.5rem !important; }

:global(.pt-4),
:global(.py-4) {
  padding-top: 1.5rem !important; }

:global(.pr-4),
:global(.px-4) {
  padding-right: 1.5rem !important; }

:global(.pb-4),
:global(.py-4) {
  padding-bottom: 1.5rem !important; }

:global(.pl-4),
:global(.px-4) {
  padding-left: 1.5rem !important; }

:global(.p-5) {
  padding: 3rem !important; }

:global(.pt-5),
:global(.py-5) {
  padding-top: 3rem !important; }

:global(.pr-5),
:global(.px-5) {
  padding-right: 3rem !important; }

:global(.pb-5),
:global(.py-5) {
  padding-bottom: 3rem !important; }

:global(.pl-5),
:global(.px-5) {
  padding-left: 3rem !important; }

:global(.m-n1) {
  margin: -0.25rem !important; }

:global(.mt-n1),
:global(.my-n1) {
  margin-top: -0.25rem !important; }

:global(.mr-n1),
:global(.mx-n1) {
  margin-right: -0.25rem !important; }

:global(.mb-n1),
:global(.my-n1) {
  margin-bottom: -0.25rem !important; }

:global(.ml-n1),
:global(.mx-n1) {
  margin-left: -0.25rem !important; }

:global(.m-n2) {
  margin: -0.5rem !important; }

:global(.mt-n2),
:global(.my-n2) {
  margin-top: -0.5rem !important; }

:global(.mr-n2),
:global(.mx-n2) {
  margin-right: -0.5rem !important; }

:global(.mb-n2),
:global(.my-n2) {
  margin-bottom: -0.5rem !important; }

:global(.ml-n2),
:global(.mx-n2) {
  margin-left: -0.5rem !important; }

:global(.m-n3) {
  margin: -1rem !important; }

:global(.mt-n3),
:global(.my-n3) {
  margin-top: -1rem !important; }

:global(.mr-n3),
:global(.mx-n3) {
  margin-right: -1rem !important; }

:global(.mb-n3),
:global(.my-n3) {
  margin-bottom: -1rem !important; }

:global(.ml-n3),
:global(.mx-n3) {
  margin-left: -1rem !important; }

:global(.m-n4) {
  margin: -1.5rem !important; }

:global(.mt-n4),
:global(.my-n4) {
  margin-top: -1.5rem !important; }

:global(.mr-n4),
:global(.mx-n4) {
  margin-right: -1.5rem !important; }

:global(.mb-n4),
:global(.my-n4) {
  margin-bottom: -1.5rem !important; }

:global(.ml-n4),
:global(.mx-n4) {
  margin-left: -1.5rem !important; }

:global(.m-n5) {
  margin: -3rem !important; }

:global(.mt-n5),
:global(.my-n5) {
  margin-top: -3rem !important; }

:global(.mr-n5),
:global(.mx-n5) {
  margin-right: -3rem !important; }

:global(.mb-n5),
:global(.my-n5) {
  margin-bottom: -3rem !important; }

:global(.ml-n5),
:global(.mx-n5) {
  margin-left: -3rem !important; }

:global(.m-auto) {
  margin: auto !important; }

:global(.mt-auto),
:global(.my-auto) {
  margin-top: auto !important; }

:global(.mr-auto),
:global(.mx-auto) {
  margin-right: auto !important; }

:global(.mb-auto),
:global(.my-auto) {
  margin-bottom: auto !important; }

:global(.ml-auto),
:global(.mx-auto) {
  margin-left: auto !important; }

@media (min-width: 576px) {
  :global(.m-sm-0) {
    margin: 0 !important; }
  :global(.mt-sm-0),
  :global(.my-sm-0) {
    margin-top: 0 !important; }
  :global(.mr-sm-0),
  :global(.mx-sm-0) {
    margin-right: 0 !important; }
  :global(.mb-sm-0),
  :global(.my-sm-0) {
    margin-bottom: 0 !important; }
  :global(.ml-sm-0),
  :global(.mx-sm-0) {
    margin-left: 0 !important; }
  :global(.m-sm-1) {
    margin: 0.25rem !important; }
  :global(.mt-sm-1),
  :global(.my-sm-1) {
    margin-top: 0.25rem !important; }
  :global(.mr-sm-1),
  :global(.mx-sm-1) {
    margin-right: 0.25rem !important; }
  :global(.mb-sm-1),
  :global(.my-sm-1) {
    margin-bottom: 0.25rem !important; }
  :global(.ml-sm-1),
  :global(.mx-sm-1) {
    margin-left: 0.25rem !important; }
  :global(.m-sm-2) {
    margin: 0.5rem !important; }
  :global(.mt-sm-2),
  :global(.my-sm-2) {
    margin-top: 0.5rem !important; }
  :global(.mr-sm-2),
  :global(.mx-sm-2) {
    margin-right: 0.5rem !important; }
  :global(.mb-sm-2),
  :global(.my-sm-2) {
    margin-bottom: 0.5rem !important; }
  :global(.ml-sm-2),
  :global(.mx-sm-2) {
    margin-left: 0.5rem !important; }
  :global(.m-sm-3) {
    margin: 1rem !important; }
  :global(.mt-sm-3),
  :global(.my-sm-3) {
    margin-top: 1rem !important; }
  :global(.mr-sm-3),
  :global(.mx-sm-3) {
    margin-right: 1rem !important; }
  :global(.mb-sm-3),
  :global(.my-sm-3) {
    margin-bottom: 1rem !important; }
  :global(.ml-sm-3),
  :global(.mx-sm-3) {
    margin-left: 1rem !important; }
  :global(.m-sm-4) {
    margin: 1.5rem !important; }
  :global(.mt-sm-4),
  :global(.my-sm-4) {
    margin-top: 1.5rem !important; }
  :global(.mr-sm-4),
  :global(.mx-sm-4) {
    margin-right: 1.5rem !important; }
  :global(.mb-sm-4),
  :global(.my-sm-4) {
    margin-bottom: 1.5rem !important; }
  :global(.ml-sm-4),
  :global(.mx-sm-4) {
    margin-left: 1.5rem !important; }
  :global(.m-sm-5) {
    margin: 3rem !important; }
  :global(.mt-sm-5),
  :global(.my-sm-5) {
    margin-top: 3rem !important; }
  :global(.mr-sm-5),
  :global(.mx-sm-5) {
    margin-right: 3rem !important; }
  :global(.mb-sm-5),
  :global(.my-sm-5) {
    margin-bottom: 3rem !important; }
  :global(.ml-sm-5),
  :global(.mx-sm-5) {
    margin-left: 3rem !important; }
  :global(.p-sm-0) {
    padding: 0 !important; }
  :global(.pt-sm-0),
  :global(.py-sm-0) {
    padding-top: 0 !important; }
  :global(.pr-sm-0),
  :global(.px-sm-0) {
    padding-right: 0 !important; }
  :global(.pb-sm-0),
  :global(.py-sm-0) {
    padding-bottom: 0 !important; }
  :global(.pl-sm-0),
  :global(.px-sm-0) {
    padding-left: 0 !important; }
  :global(.p-sm-1) {
    padding: 0.25rem !important; }
  :global(.pt-sm-1),
  :global(.py-sm-1) {
    padding-top: 0.25rem !important; }
  :global(.pr-sm-1),
  :global(.px-sm-1) {
    padding-right: 0.25rem !important; }
  :global(.pb-sm-1),
  :global(.py-sm-1) {
    padding-bottom: 0.25rem !important; }
  :global(.pl-sm-1),
  :global(.px-sm-1) {
    padding-left: 0.25rem !important; }
  :global(.p-sm-2) {
    padding: 0.5rem !important; }
  :global(.pt-sm-2),
  :global(.py-sm-2) {
    padding-top: 0.5rem !important; }
  :global(.pr-sm-2),
  :global(.px-sm-2) {
    padding-right: 0.5rem !important; }
  :global(.pb-sm-2),
  :global(.py-sm-2) {
    padding-bottom: 0.5rem !important; }
  :global(.pl-sm-2),
  :global(.px-sm-2) {
    padding-left: 0.5rem !important; }
  :global(.p-sm-3) {
    padding: 1rem !important; }
  :global(.pt-sm-3),
  :global(.py-sm-3) {
    padding-top: 1rem !important; }
  :global(.pr-sm-3),
  :global(.px-sm-3) {
    padding-right: 1rem !important; }
  :global(.pb-sm-3),
  :global(.py-sm-3) {
    padding-bottom: 1rem !important; }
  :global(.pl-sm-3),
  :global(.px-sm-3) {
    padding-left: 1rem !important; }
  :global(.p-sm-4) {
    padding: 1.5rem !important; }
  :global(.pt-sm-4),
  :global(.py-sm-4) {
    padding-top: 1.5rem !important; }
  :global(.pr-sm-4),
  :global(.px-sm-4) {
    padding-right: 1.5rem !important; }
  :global(.pb-sm-4),
  :global(.py-sm-4) {
    padding-bottom: 1.5rem !important; }
  :global(.pl-sm-4),
  :global(.px-sm-4) {
    padding-left: 1.5rem !important; }
  :global(.p-sm-5) {
    padding: 3rem !important; }
  :global(.pt-sm-5),
  :global(.py-sm-5) {
    padding-top: 3rem !important; }
  :global(.pr-sm-5),
  :global(.px-sm-5) {
    padding-right: 3rem !important; }
  :global(.pb-sm-5),
  :global(.py-sm-5) {
    padding-bottom: 3rem !important; }
  :global(.pl-sm-5),
  :global(.px-sm-5) {
    padding-left: 3rem !important; }
  :global(.m-sm-n1) {
    margin: -0.25rem !important; }
  :global(.mt-sm-n1),
  :global(.my-sm-n1) {
    margin-top: -0.25rem !important; }
  :global(.mr-sm-n1),
  :global(.mx-sm-n1) {
    margin-right: -0.25rem !important; }
  :global(.mb-sm-n1),
  :global(.my-sm-n1) {
    margin-bottom: -0.25rem !important; }
  :global(.ml-sm-n1),
  :global(.mx-sm-n1) {
    margin-left: -0.25rem !important; }
  :global(.m-sm-n2) {
    margin: -0.5rem !important; }
  :global(.mt-sm-n2),
  :global(.my-sm-n2) {
    margin-top: -0.5rem !important; }
  :global(.mr-sm-n2),
  :global(.mx-sm-n2) {
    margin-right: -0.5rem !important; }
  :global(.mb-sm-n2),
  :global(.my-sm-n2) {
    margin-bottom: -0.5rem !important; }
  :global(.ml-sm-n2),
  :global(.mx-sm-n2) {
    margin-left: -0.5rem !important; }
  :global(.m-sm-n3) {
    margin: -1rem !important; }
  :global(.mt-sm-n3),
  :global(.my-sm-n3) {
    margin-top: -1rem !important; }
  :global(.mr-sm-n3),
  :global(.mx-sm-n3) {
    margin-right: -1rem !important; }
  :global(.mb-sm-n3),
  :global(.my-sm-n3) {
    margin-bottom: -1rem !important; }
  :global(.ml-sm-n3),
  :global(.mx-sm-n3) {
    margin-left: -1rem !important; }
  :global(.m-sm-n4) {
    margin: -1.5rem !important; }
  :global(.mt-sm-n4),
  :global(.my-sm-n4) {
    margin-top: -1.5rem !important; }
  :global(.mr-sm-n4),
  :global(.mx-sm-n4) {
    margin-right: -1.5rem !important; }
  :global(.mb-sm-n4),
  :global(.my-sm-n4) {
    margin-bottom: -1.5rem !important; }
  :global(.ml-sm-n4),
  :global(.mx-sm-n4) {
    margin-left: -1.5rem !important; }
  :global(.m-sm-n5) {
    margin: -3rem !important; }
  :global(.mt-sm-n5),
  :global(.my-sm-n5) {
    margin-top: -3rem !important; }
  :global(.mr-sm-n5),
  :global(.mx-sm-n5) {
    margin-right: -3rem !important; }
  :global(.mb-sm-n5),
  :global(.my-sm-n5) {
    margin-bottom: -3rem !important; }
  :global(.ml-sm-n5),
  :global(.mx-sm-n5) {
    margin-left: -3rem !important; }
  :global(.m-sm-auto) {
    margin: auto !important; }
  :global(.mt-sm-auto),
  :global(.my-sm-auto) {
    margin-top: auto !important; }
  :global(.mr-sm-auto),
  :global(.mx-sm-auto) {
    margin-right: auto !important; }
  :global(.mb-sm-auto),
  :global(.my-sm-auto) {
    margin-bottom: auto !important; }
  :global(.ml-sm-auto),
  :global(.mx-sm-auto) {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  :global(.m-md-0) {
    margin: 0 !important; }
  :global(.mt-md-0),
  :global(.my-md-0) {
    margin-top: 0 !important; }
  :global(.mr-md-0),
  :global(.mx-md-0) {
    margin-right: 0 !important; }
  :global(.mb-md-0),
  :global(.my-md-0) {
    margin-bottom: 0 !important; }
  :global(.ml-md-0),
  :global(.mx-md-0) {
    margin-left: 0 !important; }
  :global(.m-md-1) {
    margin: 0.25rem !important; }
  :global(.mt-md-1),
  :global(.my-md-1) {
    margin-top: 0.25rem !important; }
  :global(.mr-md-1),
  :global(.mx-md-1) {
    margin-right: 0.25rem !important; }
  :global(.mb-md-1),
  :global(.my-md-1) {
    margin-bottom: 0.25rem !important; }
  :global(.ml-md-1),
  :global(.mx-md-1) {
    margin-left: 0.25rem !important; }
  :global(.m-md-2) {
    margin: 0.5rem !important; }
  :global(.mt-md-2),
  :global(.my-md-2) {
    margin-top: 0.5rem !important; }
  :global(.mr-md-2),
  :global(.mx-md-2) {
    margin-right: 0.5rem !important; }
  :global(.mb-md-2),
  :global(.my-md-2) {
    margin-bottom: 0.5rem !important; }
  :global(.ml-md-2),
  :global(.mx-md-2) {
    margin-left: 0.5rem !important; }
  :global(.m-md-3) {
    margin: 1rem !important; }
  :global(.mt-md-3),
  :global(.my-md-3) {
    margin-top: 1rem !important; }
  :global(.mr-md-3),
  :global(.mx-md-3) {
    margin-right: 1rem !important; }
  :global(.mb-md-3),
  :global(.my-md-3) {
    margin-bottom: 1rem !important; }
  :global(.ml-md-3),
  :global(.mx-md-3) {
    margin-left: 1rem !important; }
  :global(.m-md-4) {
    margin: 1.5rem !important; }
  :global(.mt-md-4),
  :global(.my-md-4) {
    margin-top: 1.5rem !important; }
  :global(.mr-md-4),
  :global(.mx-md-4) {
    margin-right: 1.5rem !important; }
  :global(.mb-md-4),
  :global(.my-md-4) {
    margin-bottom: 1.5rem !important; }
  :global(.ml-md-4),
  :global(.mx-md-4) {
    margin-left: 1.5rem !important; }
  :global(.m-md-5) {
    margin: 3rem !important; }
  :global(.mt-md-5),
  :global(.my-md-5) {
    margin-top: 3rem !important; }
  :global(.mr-md-5),
  :global(.mx-md-5) {
    margin-right: 3rem !important; }
  :global(.mb-md-5),
  :global(.my-md-5) {
    margin-bottom: 3rem !important; }
  :global(.ml-md-5),
  :global(.mx-md-5) {
    margin-left: 3rem !important; }
  :global(.p-md-0) {
    padding: 0 !important; }
  :global(.pt-md-0),
  :global(.py-md-0) {
    padding-top: 0 !important; }
  :global(.pr-md-0),
  :global(.px-md-0) {
    padding-right: 0 !important; }
  :global(.pb-md-0),
  :global(.py-md-0) {
    padding-bottom: 0 !important; }
  :global(.pl-md-0),
  :global(.px-md-0) {
    padding-left: 0 !important; }
  :global(.p-md-1) {
    padding: 0.25rem !important; }
  :global(.pt-md-1),
  :global(.py-md-1) {
    padding-top: 0.25rem !important; }
  :global(.pr-md-1),
  :global(.px-md-1) {
    padding-right: 0.25rem !important; }
  :global(.pb-md-1),
  :global(.py-md-1) {
    padding-bottom: 0.25rem !important; }
  :global(.pl-md-1),
  :global(.px-md-1) {
    padding-left: 0.25rem !important; }
  :global(.p-md-2) {
    padding: 0.5rem !important; }
  :global(.pt-md-2),
  :global(.py-md-2) {
    padding-top: 0.5rem !important; }
  :global(.pr-md-2),
  :global(.px-md-2) {
    padding-right: 0.5rem !important; }
  :global(.pb-md-2),
  :global(.py-md-2) {
    padding-bottom: 0.5rem !important; }
  :global(.pl-md-2),
  :global(.px-md-2) {
    padding-left: 0.5rem !important; }
  :global(.p-md-3) {
    padding: 1rem !important; }
  :global(.pt-md-3),
  :global(.py-md-3) {
    padding-top: 1rem !important; }
  :global(.pr-md-3),
  :global(.px-md-3) {
    padding-right: 1rem !important; }
  :global(.pb-md-3),
  :global(.py-md-3) {
    padding-bottom: 1rem !important; }
  :global(.pl-md-3),
  :global(.px-md-3) {
    padding-left: 1rem !important; }
  :global(.p-md-4) {
    padding: 1.5rem !important; }
  :global(.pt-md-4),
  :global(.py-md-4) {
    padding-top: 1.5rem !important; }
  :global(.pr-md-4),
  :global(.px-md-4) {
    padding-right: 1.5rem !important; }
  :global(.pb-md-4),
  :global(.py-md-4) {
    padding-bottom: 1.5rem !important; }
  :global(.pl-md-4),
  :global(.px-md-4) {
    padding-left: 1.5rem !important; }
  :global(.p-md-5) {
    padding: 3rem !important; }
  :global(.pt-md-5),
  :global(.py-md-5) {
    padding-top: 3rem !important; }
  :global(.pr-md-5),
  :global(.px-md-5) {
    padding-right: 3rem !important; }
  :global(.pb-md-5),
  :global(.py-md-5) {
    padding-bottom: 3rem !important; }
  :global(.pl-md-5),
  :global(.px-md-5) {
    padding-left: 3rem !important; }
  :global(.m-md-n1) {
    margin: -0.25rem !important; }
  :global(.mt-md-n1),
  :global(.my-md-n1) {
    margin-top: -0.25rem !important; }
  :global(.mr-md-n1),
  :global(.mx-md-n1) {
    margin-right: -0.25rem !important; }
  :global(.mb-md-n1),
  :global(.my-md-n1) {
    margin-bottom: -0.25rem !important; }
  :global(.ml-md-n1),
  :global(.mx-md-n1) {
    margin-left: -0.25rem !important; }
  :global(.m-md-n2) {
    margin: -0.5rem !important; }
  :global(.mt-md-n2),
  :global(.my-md-n2) {
    margin-top: -0.5rem !important; }
  :global(.mr-md-n2),
  :global(.mx-md-n2) {
    margin-right: -0.5rem !important; }
  :global(.mb-md-n2),
  :global(.my-md-n2) {
    margin-bottom: -0.5rem !important; }
  :global(.ml-md-n2),
  :global(.mx-md-n2) {
    margin-left: -0.5rem !important; }
  :global(.m-md-n3) {
    margin: -1rem !important; }
  :global(.mt-md-n3),
  :global(.my-md-n3) {
    margin-top: -1rem !important; }
  :global(.mr-md-n3),
  :global(.mx-md-n3) {
    margin-right: -1rem !important; }
  :global(.mb-md-n3),
  :global(.my-md-n3) {
    margin-bottom: -1rem !important; }
  :global(.ml-md-n3),
  :global(.mx-md-n3) {
    margin-left: -1rem !important; }
  :global(.m-md-n4) {
    margin: -1.5rem !important; }
  :global(.mt-md-n4),
  :global(.my-md-n4) {
    margin-top: -1.5rem !important; }
  :global(.mr-md-n4),
  :global(.mx-md-n4) {
    margin-right: -1.5rem !important; }
  :global(.mb-md-n4),
  :global(.my-md-n4) {
    margin-bottom: -1.5rem !important; }
  :global(.ml-md-n4),
  :global(.mx-md-n4) {
    margin-left: -1.5rem !important; }
  :global(.m-md-n5) {
    margin: -3rem !important; }
  :global(.mt-md-n5),
  :global(.my-md-n5) {
    margin-top: -3rem !important; }
  :global(.mr-md-n5),
  :global(.mx-md-n5) {
    margin-right: -3rem !important; }
  :global(.mb-md-n5),
  :global(.my-md-n5) {
    margin-bottom: -3rem !important; }
  :global(.ml-md-n5),
  :global(.mx-md-n5) {
    margin-left: -3rem !important; }
  :global(.m-md-auto) {
    margin: auto !important; }
  :global(.mt-md-auto),
  :global(.my-md-auto) {
    margin-top: auto !important; }
  :global(.mr-md-auto),
  :global(.mx-md-auto) {
    margin-right: auto !important; }
  :global(.mb-md-auto),
  :global(.my-md-auto) {
    margin-bottom: auto !important; }
  :global(.ml-md-auto),
  :global(.mx-md-auto) {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  :global(.m-lg-0) {
    margin: 0 !important; }
  :global(.mt-lg-0),
  :global(.my-lg-0) {
    margin-top: 0 !important; }
  :global(.mr-lg-0),
  :global(.mx-lg-0) {
    margin-right: 0 !important; }
  :global(.mb-lg-0),
  :global(.my-lg-0) {
    margin-bottom: 0 !important; }
  :global(.ml-lg-0),
  :global(.mx-lg-0) {
    margin-left: 0 !important; }
  :global(.m-lg-1) {
    margin: 0.25rem !important; }
  :global(.mt-lg-1),
  :global(.my-lg-1) {
    margin-top: 0.25rem !important; }
  :global(.mr-lg-1),
  :global(.mx-lg-1) {
    margin-right: 0.25rem !important; }
  :global(.mb-lg-1),
  :global(.my-lg-1) {
    margin-bottom: 0.25rem !important; }
  :global(.ml-lg-1),
  :global(.mx-lg-1) {
    margin-left: 0.25rem !important; }
  :global(.m-lg-2) {
    margin: 0.5rem !important; }
  :global(.mt-lg-2),
  :global(.my-lg-2) {
    margin-top: 0.5rem !important; }
  :global(.mr-lg-2),
  :global(.mx-lg-2) {
    margin-right: 0.5rem !important; }
  :global(.mb-lg-2),
  :global(.my-lg-2) {
    margin-bottom: 0.5rem !important; }
  :global(.ml-lg-2),
  :global(.mx-lg-2) {
    margin-left: 0.5rem !important; }
  :global(.m-lg-3) {
    margin: 1rem !important; }
  :global(.mt-lg-3),
  :global(.my-lg-3) {
    margin-top: 1rem !important; }
  :global(.mr-lg-3),
  :global(.mx-lg-3) {
    margin-right: 1rem !important; }
  :global(.mb-lg-3),
  :global(.my-lg-3) {
    margin-bottom: 1rem !important; }
  :global(.ml-lg-3),
  :global(.mx-lg-3) {
    margin-left: 1rem !important; }
  :global(.m-lg-4) {
    margin: 1.5rem !important; }
  :global(.mt-lg-4),
  :global(.my-lg-4) {
    margin-top: 1.5rem !important; }
  :global(.mr-lg-4),
  :global(.mx-lg-4) {
    margin-right: 1.5rem !important; }
  :global(.mb-lg-4),
  :global(.my-lg-4) {
    margin-bottom: 1.5rem !important; }
  :global(.ml-lg-4),
  :global(.mx-lg-4) {
    margin-left: 1.5rem !important; }
  :global(.m-lg-5) {
    margin: 3rem !important; }
  :global(.mt-lg-5),
  :global(.my-lg-5) {
    margin-top: 3rem !important; }
  :global(.mr-lg-5),
  :global(.mx-lg-5) {
    margin-right: 3rem !important; }
  :global(.mb-lg-5),
  :global(.my-lg-5) {
    margin-bottom: 3rem !important; }
  :global(.ml-lg-5),
  :global(.mx-lg-5) {
    margin-left: 3rem !important; }
  :global(.p-lg-0) {
    padding: 0 !important; }
  :global(.pt-lg-0),
  :global(.py-lg-0) {
    padding-top: 0 !important; }
  :global(.pr-lg-0),
  :global(.px-lg-0) {
    padding-right: 0 !important; }
  :global(.pb-lg-0),
  :global(.py-lg-0) {
    padding-bottom: 0 !important; }
  :global(.pl-lg-0),
  :global(.px-lg-0) {
    padding-left: 0 !important; }
  :global(.p-lg-1) {
    padding: 0.25rem !important; }
  :global(.pt-lg-1),
  :global(.py-lg-1) {
    padding-top: 0.25rem !important; }
  :global(.pr-lg-1),
  :global(.px-lg-1) {
    padding-right: 0.25rem !important; }
  :global(.pb-lg-1),
  :global(.py-lg-1) {
    padding-bottom: 0.25rem !important; }
  :global(.pl-lg-1),
  :global(.px-lg-1) {
    padding-left: 0.25rem !important; }
  :global(.p-lg-2) {
    padding: 0.5rem !important; }
  :global(.pt-lg-2),
  :global(.py-lg-2) {
    padding-top: 0.5rem !important; }
  :global(.pr-lg-2),
  :global(.px-lg-2) {
    padding-right: 0.5rem !important; }
  :global(.pb-lg-2),
  :global(.py-lg-2) {
    padding-bottom: 0.5rem !important; }
  :global(.pl-lg-2),
  :global(.px-lg-2) {
    padding-left: 0.5rem !important; }
  :global(.p-lg-3) {
    padding: 1rem !important; }
  :global(.pt-lg-3),
  :global(.py-lg-3) {
    padding-top: 1rem !important; }
  :global(.pr-lg-3),
  :global(.px-lg-3) {
    padding-right: 1rem !important; }
  :global(.pb-lg-3),
  :global(.py-lg-3) {
    padding-bottom: 1rem !important; }
  :global(.pl-lg-3),
  :global(.px-lg-3) {
    padding-left: 1rem !important; }
  :global(.p-lg-4) {
    padding: 1.5rem !important; }
  :global(.pt-lg-4),
  :global(.py-lg-4) {
    padding-top: 1.5rem !important; }
  :global(.pr-lg-4),
  :global(.px-lg-4) {
    padding-right: 1.5rem !important; }
  :global(.pb-lg-4),
  :global(.py-lg-4) {
    padding-bottom: 1.5rem !important; }
  :global(.pl-lg-4),
  :global(.px-lg-4) {
    padding-left: 1.5rem !important; }
  :global(.p-lg-5) {
    padding: 3rem !important; }
  :global(.pt-lg-5),
  :global(.py-lg-5) {
    padding-top: 3rem !important; }
  :global(.pr-lg-5),
  :global(.px-lg-5) {
    padding-right: 3rem !important; }
  :global(.pb-lg-5),
  :global(.py-lg-5) {
    padding-bottom: 3rem !important; }
  :global(.pl-lg-5),
  :global(.px-lg-5) {
    padding-left: 3rem !important; }
  :global(.m-lg-n1) {
    margin: -0.25rem !important; }
  :global(.mt-lg-n1),
  :global(.my-lg-n1) {
    margin-top: -0.25rem !important; }
  :global(.mr-lg-n1),
  :global(.mx-lg-n1) {
    margin-right: -0.25rem !important; }
  :global(.mb-lg-n1),
  :global(.my-lg-n1) {
    margin-bottom: -0.25rem !important; }
  :global(.ml-lg-n1),
  :global(.mx-lg-n1) {
    margin-left: -0.25rem !important; }
  :global(.m-lg-n2) {
    margin: -0.5rem !important; }
  :global(.mt-lg-n2),
  :global(.my-lg-n2) {
    margin-top: -0.5rem !important; }
  :global(.mr-lg-n2),
  :global(.mx-lg-n2) {
    margin-right: -0.5rem !important; }
  :global(.mb-lg-n2),
  :global(.my-lg-n2) {
    margin-bottom: -0.5rem !important; }
  :global(.ml-lg-n2),
  :global(.mx-lg-n2) {
    margin-left: -0.5rem !important; }
  :global(.m-lg-n3) {
    margin: -1rem !important; }
  :global(.mt-lg-n3),
  :global(.my-lg-n3) {
    margin-top: -1rem !important; }
  :global(.mr-lg-n3),
  :global(.mx-lg-n3) {
    margin-right: -1rem !important; }
  :global(.mb-lg-n3),
  :global(.my-lg-n3) {
    margin-bottom: -1rem !important; }
  :global(.ml-lg-n3),
  :global(.mx-lg-n3) {
    margin-left: -1rem !important; }
  :global(.m-lg-n4) {
    margin: -1.5rem !important; }
  :global(.mt-lg-n4),
  :global(.my-lg-n4) {
    margin-top: -1.5rem !important; }
  :global(.mr-lg-n4),
  :global(.mx-lg-n4) {
    margin-right: -1.5rem !important; }
  :global(.mb-lg-n4),
  :global(.my-lg-n4) {
    margin-bottom: -1.5rem !important; }
  :global(.ml-lg-n4),
  :global(.mx-lg-n4) {
    margin-left: -1.5rem !important; }
  :global(.m-lg-n5) {
    margin: -3rem !important; }
  :global(.mt-lg-n5),
  :global(.my-lg-n5) {
    margin-top: -3rem !important; }
  :global(.mr-lg-n5),
  :global(.mx-lg-n5) {
    margin-right: -3rem !important; }
  :global(.mb-lg-n5),
  :global(.my-lg-n5) {
    margin-bottom: -3rem !important; }
  :global(.ml-lg-n5),
  :global(.mx-lg-n5) {
    margin-left: -3rem !important; }
  :global(.m-lg-auto) {
    margin: auto !important; }
  :global(.mt-lg-auto),
  :global(.my-lg-auto) {
    margin-top: auto !important; }
  :global(.mr-lg-auto),
  :global(.mx-lg-auto) {
    margin-right: auto !important; }
  :global(.mb-lg-auto),
  :global(.my-lg-auto) {
    margin-bottom: auto !important; }
  :global(.ml-lg-auto),
  :global(.mx-lg-auto) {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  :global(.m-xl-0) {
    margin: 0 !important; }
  :global(.mt-xl-0),
  :global(.my-xl-0) {
    margin-top: 0 !important; }
  :global(.mr-xl-0),
  :global(.mx-xl-0) {
    margin-right: 0 !important; }
  :global(.mb-xl-0),
  :global(.my-xl-0) {
    margin-bottom: 0 !important; }
  :global(.ml-xl-0),
  :global(.mx-xl-0) {
    margin-left: 0 !important; }
  :global(.m-xl-1) {
    margin: 0.25rem !important; }
  :global(.mt-xl-1),
  :global(.my-xl-1) {
    margin-top: 0.25rem !important; }
  :global(.mr-xl-1),
  :global(.mx-xl-1) {
    margin-right: 0.25rem !important; }
  :global(.mb-xl-1),
  :global(.my-xl-1) {
    margin-bottom: 0.25rem !important; }
  :global(.ml-xl-1),
  :global(.mx-xl-1) {
    margin-left: 0.25rem !important; }
  :global(.m-xl-2) {
    margin: 0.5rem !important; }
  :global(.mt-xl-2),
  :global(.my-xl-2) {
    margin-top: 0.5rem !important; }
  :global(.mr-xl-2),
  :global(.mx-xl-2) {
    margin-right: 0.5rem !important; }
  :global(.mb-xl-2),
  :global(.my-xl-2) {
    margin-bottom: 0.5rem !important; }
  :global(.ml-xl-2),
  :global(.mx-xl-2) {
    margin-left: 0.5rem !important; }
  :global(.m-xl-3) {
    margin: 1rem !important; }
  :global(.mt-xl-3),
  :global(.my-xl-3) {
    margin-top: 1rem !important; }
  :global(.mr-xl-3),
  :global(.mx-xl-3) {
    margin-right: 1rem !important; }
  :global(.mb-xl-3),
  :global(.my-xl-3) {
    margin-bottom: 1rem !important; }
  :global(.ml-xl-3),
  :global(.mx-xl-3) {
    margin-left: 1rem !important; }
  :global(.m-xl-4) {
    margin: 1.5rem !important; }
  :global(.mt-xl-4),
  :global(.my-xl-4) {
    margin-top: 1.5rem !important; }
  :global(.mr-xl-4),
  :global(.mx-xl-4) {
    margin-right: 1.5rem !important; }
  :global(.mb-xl-4),
  :global(.my-xl-4) {
    margin-bottom: 1.5rem !important; }
  :global(.ml-xl-4),
  :global(.mx-xl-4) {
    margin-left: 1.5rem !important; }
  :global(.m-xl-5) {
    margin: 3rem !important; }
  :global(.mt-xl-5),
  :global(.my-xl-5) {
    margin-top: 3rem !important; }
  :global(.mr-xl-5),
  :global(.mx-xl-5) {
    margin-right: 3rem !important; }
  :global(.mb-xl-5),
  :global(.my-xl-5) {
    margin-bottom: 3rem !important; }
  :global(.ml-xl-5),
  :global(.mx-xl-5) {
    margin-left: 3rem !important; }
  :global(.p-xl-0) {
    padding: 0 !important; }
  :global(.pt-xl-0),
  :global(.py-xl-0) {
    padding-top: 0 !important; }
  :global(.pr-xl-0),
  :global(.px-xl-0) {
    padding-right: 0 !important; }
  :global(.pb-xl-0),
  :global(.py-xl-0) {
    padding-bottom: 0 !important; }
  :global(.pl-xl-0),
  :global(.px-xl-0) {
    padding-left: 0 !important; }
  :global(.p-xl-1) {
    padding: 0.25rem !important; }
  :global(.pt-xl-1),
  :global(.py-xl-1) {
    padding-top: 0.25rem !important; }
  :global(.pr-xl-1),
  :global(.px-xl-1) {
    padding-right: 0.25rem !important; }
  :global(.pb-xl-1),
  :global(.py-xl-1) {
    padding-bottom: 0.25rem !important; }
  :global(.pl-xl-1),
  :global(.px-xl-1) {
    padding-left: 0.25rem !important; }
  :global(.p-xl-2) {
    padding: 0.5rem !important; }
  :global(.pt-xl-2),
  :global(.py-xl-2) {
    padding-top: 0.5rem !important; }
  :global(.pr-xl-2),
  :global(.px-xl-2) {
    padding-right: 0.5rem !important; }
  :global(.pb-xl-2),
  :global(.py-xl-2) {
    padding-bottom: 0.5rem !important; }
  :global(.pl-xl-2),
  :global(.px-xl-2) {
    padding-left: 0.5rem !important; }
  :global(.p-xl-3) {
    padding: 1rem !important; }
  :global(.pt-xl-3),
  :global(.py-xl-3) {
    padding-top: 1rem !important; }
  :global(.pr-xl-3),
  :global(.px-xl-3) {
    padding-right: 1rem !important; }
  :global(.pb-xl-3),
  :global(.py-xl-3) {
    padding-bottom: 1rem !important; }
  :global(.pl-xl-3),
  :global(.px-xl-3) {
    padding-left: 1rem !important; }
  :global(.p-xl-4) {
    padding: 1.5rem !important; }
  :global(.pt-xl-4),
  :global(.py-xl-4) {
    padding-top: 1.5rem !important; }
  :global(.pr-xl-4),
  :global(.px-xl-4) {
    padding-right: 1.5rem !important; }
  :global(.pb-xl-4),
  :global(.py-xl-4) {
    padding-bottom: 1.5rem !important; }
  :global(.pl-xl-4),
  :global(.px-xl-4) {
    padding-left: 1.5rem !important; }
  :global(.p-xl-5) {
    padding: 3rem !important; }
  :global(.pt-xl-5),
  :global(.py-xl-5) {
    padding-top: 3rem !important; }
  :global(.pr-xl-5),
  :global(.px-xl-5) {
    padding-right: 3rem !important; }
  :global(.pb-xl-5),
  :global(.py-xl-5) {
    padding-bottom: 3rem !important; }
  :global(.pl-xl-5),
  :global(.px-xl-5) {
    padding-left: 3rem !important; }
  :global(.m-xl-n1) {
    margin: -0.25rem !important; }
  :global(.mt-xl-n1),
  :global(.my-xl-n1) {
    margin-top: -0.25rem !important; }
  :global(.mr-xl-n1),
  :global(.mx-xl-n1) {
    margin-right: -0.25rem !important; }
  :global(.mb-xl-n1),
  :global(.my-xl-n1) {
    margin-bottom: -0.25rem !important; }
  :global(.ml-xl-n1),
  :global(.mx-xl-n1) {
    margin-left: -0.25rem !important; }
  :global(.m-xl-n2) {
    margin: -0.5rem !important; }
  :global(.mt-xl-n2),
  :global(.my-xl-n2) {
    margin-top: -0.5rem !important; }
  :global(.mr-xl-n2),
  :global(.mx-xl-n2) {
    margin-right: -0.5rem !important; }
  :global(.mb-xl-n2),
  :global(.my-xl-n2) {
    margin-bottom: -0.5rem !important; }
  :global(.ml-xl-n2),
  :global(.mx-xl-n2) {
    margin-left: -0.5rem !important; }
  :global(.m-xl-n3) {
    margin: -1rem !important; }
  :global(.mt-xl-n3),
  :global(.my-xl-n3) {
    margin-top: -1rem !important; }
  :global(.mr-xl-n3),
  :global(.mx-xl-n3) {
    margin-right: -1rem !important; }
  :global(.mb-xl-n3),
  :global(.my-xl-n3) {
    margin-bottom: -1rem !important; }
  :global(.ml-xl-n3),
  :global(.mx-xl-n3) {
    margin-left: -1rem !important; }
  :global(.m-xl-n4) {
    margin: -1.5rem !important; }
  :global(.mt-xl-n4),
  :global(.my-xl-n4) {
    margin-top: -1.5rem !important; }
  :global(.mr-xl-n4),
  :global(.mx-xl-n4) {
    margin-right: -1.5rem !important; }
  :global(.mb-xl-n4),
  :global(.my-xl-n4) {
    margin-bottom: -1.5rem !important; }
  :global(.ml-xl-n4),
  :global(.mx-xl-n4) {
    margin-left: -1.5rem !important; }
  :global(.m-xl-n5) {
    margin: -3rem !important; }
  :global(.mt-xl-n5),
  :global(.my-xl-n5) {
    margin-top: -3rem !important; }
  :global(.mr-xl-n5),
  :global(.mx-xl-n5) {
    margin-right: -3rem !important; }
  :global(.mb-xl-n5),
  :global(.my-xl-n5) {
    margin-bottom: -3rem !important; }
  :global(.ml-xl-n5),
  :global(.mx-xl-n5) {
    margin-left: -3rem !important; }
  :global(.m-xl-auto) {
    margin: auto !important; }
  :global(.mt-xl-auto),
  :global(.my-xl-auto) {
    margin-top: auto !important; }
  :global(.mr-xl-auto),
  :global(.mx-xl-auto) {
    margin-right: auto !important; }
  :global(.mb-xl-auto),
  :global(.my-xl-auto) {
    margin-bottom: auto !important; }
  :global(.ml-xl-auto),
  :global(.mx-xl-auto) {
    margin-left: auto !important; } }

:global(.stretched-link::after) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

:global(.text-monospace) {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

:global(.text-justify) {
  text-align: justify !important; }

:global(.text-wrap) {
  white-space: normal !important; }

:global(.text-nowrap) {
  white-space: nowrap !important; }

:global(.text-truncate) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

:global(.text-left) {
  text-align: left !important; }

:global(.text-right) {
  text-align: right !important; }

:global(.text-center) {
  text-align: center !important; }

@media (min-width: 576px) {
  :global(.text-sm-left) {
    text-align: left !important; }
  :global(.text-sm-right) {
    text-align: right !important; }
  :global(.text-sm-center) {
    text-align: center !important; } }

@media (min-width: 768px) {
  :global(.text-md-left) {
    text-align: left !important; }
  :global(.text-md-right) {
    text-align: right !important; }
  :global(.text-md-center) {
    text-align: center !important; } }

@media (min-width: 992px) {
  :global(.text-lg-left) {
    text-align: left !important; }
  :global(.text-lg-right) {
    text-align: right !important; }
  :global(.text-lg-center) {
    text-align: center !important; } }

@media (min-width: 1200px) {
  :global(.text-xl-left) {
    text-align: left !important; }
  :global(.text-xl-right) {
    text-align: right !important; }
  :global(.text-xl-center) {
    text-align: center !important; } }

:global(.text-lowercase) {
  text-transform: lowercase !important; }

:global(.text-uppercase) {
  text-transform: uppercase !important; }

:global(.text-capitalize) {
  text-transform: capitalize !important; }

:global(.font-weight-light) {
  font-weight: 300 !important; }

:global(.font-weight-lighter) {
  font-weight: lighter !important; }

:global(.font-weight-normal) {
  font-weight: 400 !important; }

:global(.font-weight-bold) {
  font-weight: 700 !important; }

:global(.font-weight-bolder) {
  font-weight: bolder !important; }

:global(.font-italic) {
  font-style: italic !important; }

:global(.text-white) {
  color: #fff !important; }

:global(.text-primary) {
  color: #1db954 !important; }

:global(a.text-primary:hover), :global(a.text-primary:focus) {
  color: #137736 !important; }

:global(.text-secondary) {
  color: #4a5759 !important; }

:global(a.text-secondary:hover), :global(a.text-secondary:focus) {
  color: #272e2f !important; }

:global(.text-success) {
  color: #1db954 !important; }

:global(a.text-success:hover), :global(a.text-success:focus) {
  color: #137736 !important; }

:global(.text-info) {
  color: #4059ad !important; }

:global(a.text-info:hover), :global(a.text-info:focus) {
  color: #2b3c75 !important; }

:global(.text-warning) {
  color: #f5d547 !important; }

:global(a.text-warning:hover), :global(a.text-warning:focus) {
  color: #e3bc0c !important; }

:global(.text-danger) {
  color: #f07167 !important; }

:global(a.text-danger:hover), :global(a.text-danger:focus) {
  color: #e93021 !important; }

:global(.text-light) {
  color: #dedbd2 !important; }

:global(a.text-light:hover), :global(a.text-light:focus) {
  color: #beb8a6 !important; }

:global(.text-dark) {
  color: #020122 !important; }

:global(a.text-dark:hover), :global(a.text-dark:focus) {
  color: black !important; }

:global(.text-body) {
  color: #212529 !important; }

:global(.text-muted) {
  color: #6c757d !important; }

:global(.text-black-50) {
  color: rgba(0, 0, 0, 0.5) !important; }

:global(.text-white-50) {
  color: rgba(255, 255, 255, 0.5) !important; }

:global(.text-hide) {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

:global(.text-decoration-none) {
  text-decoration: none !important; }

:global(.text-break) {
  word-break: break-word !important;
  word-wrap: break-word !important; }

:global(.text-reset) {
  color: inherit !important; }

:global(.visible) {
  visibility: visible !important; }

:global(.invisible) {
  visibility: hidden !important; }

:global(.profile-picture) {
  width: 64px;
  height: auto; }
</style>
